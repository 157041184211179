import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import url from "@/router/urls";
import api from "@/services/api";

import { appActions } from '@/store/actions/app.actions';

import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import Alert from "@Elements/Alert/Alert";
import FormInputCustom from "@Elements/Form/FormInputCustom";
import Button from "@Elements/Button/Button";

import SearchTranscriptions from "@Elements/Search/SearchTranscriptions";
import SearchTypeEnum from "@/utils/SearchTypeEnum";

import FormSubmit from "@Elements/Form/FormSubmit";
import styles from '@Pages/Transcription/TranscriptionImport/TranscriptionImport.module.sass';

import CommonDropdownForm from '@Elements/Dropdown/CommonDropdownForm';

import ModalWrapperChildren from '@Elements/Modal/ModalWrapperChildren';
import ModalAvailableImportSources from '@Elements/Modal/parts/ModalAvailableImportSources';

const importFormats = [
	{ value: 'iiif', label: "Manifest IIIF" },
	{ value: 'mets', label: 'METS' },
	{ value: 'tei', label: 'TEI' },
];

const TranscriptionImport = () => {
	const history = useHistory();
	const { t } = useTranslation();

	const dispatch = useDispatch();
	
	const daceUrl = useSelector(state => state.app.config.daceUrl);

	const [searchString, setSearchString] = useState(null);
	const [useMetadata, setUseMetadata] = useState(SearchTypeEnum.ALL.toString());

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: t('breadcrumbs.transcriptionNew'), link: url.transcription.new },
		{ id: 3, label: t('breadcrumbs.transcriptionImport') },
	];

	const formMethods = useForm({
		defaultValues: {
			resourceUrl: '',
			format: 'iiif',
		},
		mode: "onChange",
	});

	const {
		register,
		handleSubmit,
		setError,
		control,
		formState: {
			errors, isValid,
		},
		clearErrors,
	} = formMethods;

	const watchedDropdownOption = useWatch({ control, name: 'format' });

	useEffect(() => {
		let currentUrlParams = new URLSearchParams(window.location.search);
		const daceId = currentUrlParams.get("daceId");
		let resourceUrl, format;
		if (daceId) {
			resourceUrl = daceUrl + "?daceId=" + daceId + "&schemaId=dace&page=0";
			format = "dace";
		} else {
			importFormats.forEach(f => {
				if (currentUrlParams.get(f.value)) {
					resourceUrl = currentUrlParams.get(f.value);
					format = f.value;
				}
			});
		}
		if (format) {
			api.put('imports/', {
				resourceUrl: decodeURIComponent(resourceUrl),
				fileType: format,
				externalId: currentUrlParams.get("externalId") || decodeURIComponent(resourceUrl),
				metadataUrl: currentUrlParams.get("metadataUrl"),
				metadataType: currentUrlParams.get("metadataType"),
			}).then((res) => {
				dispatch(appActions.setImportedTranscription(res.data));
				history.replace(url.transcription.new);
			}).catch((error) => {
				dispatch(appActions.setLoading(false));
			});
		}
	}, [true]);

	useEffect(() => {
		clearErrors("resourceUrl");
	}, [watchedDropdownOption]);

	const onSubmit = data => {
		dispatch(appActions.setLoading(true));
		api.put('imports/', { resourceUrl: data.resourceUrl, fileType: data.format })
			.then((res) => {
				dispatch(appActions.setImportedTranscription(res.data));
				history.push(url.transcription.new);
			})
			.catch((error) => {
				dispatch(appActions.setLoading(false));
				if (error.data && error.data.resourceUrl && error.data.resourceUrl.length > 0) {
					setError('resourceUrl', 'error', t('transcription.import.errors.' + error.data.resourceUrl.replace(/[\.-]/g, "")));  // eslint-disable-line
				}
				if (error.data && error.data.code) {
					switch (error.data.code) {
						case 'domain.error.not.available.tei':
						case 'domain.error.not.available.iiif':
						case 'domain.error.not.available.mets': {
							setError('resourceUrl', 'error', t('transcription.import.errors.' + error.data.code.replace(/[\.-]/g, "")));  // eslint-disable-line
							break;
						}

						default:
							return false;
					}

				}

			});
	};

	const [modalVisible, setModalVisible] = useState(false);

	return (
		<main>
			<ModalWrapperChildren 
				modalVisible={modalVisible} 
				setModalVisible={setModalVisible}
				title={t('transcription.import.modalTitle')}
				body={`${t('transcription.import.sourceListDescription')} ${importFormats.find(el => el.value === watchedDropdownOption).label}`}
				withClose
			>
				{modalProps => (
					<ModalAvailableImportSources modalProps={modalProps} selectedImportFormat={importFormats.find(el => el.value === watchedDropdownOption)} />
				)}
			</ModalWrapperChildren>
			
			<SearchTranscriptions
				setSearchString={setSearchString}
				searchString={searchString}
				setUseMetadata={setUseMetadata}
			/>
			<div className='transcription-new__main'>
				<Breadcrumbs breadcrumbsList={breadcrumbsList} style={{padding: '0 20px'}} />
				<Alert />
				<div className='transcription-new__block'>
					<div className='transcription-new__header--container'>
						<h2>
							{t('transcription.import.title')}
						</h2>
					</div>
					<p className='transcription-import__description'>{t('transcription.import.description')}</p>

					<form
						onSubmit={handleSubmit(onSubmit)}
					>
						<FormProvider {...formMethods}>
							<div className='transcription-new__form--inline-select transcription-import__row--select'>
								{importFormats && (
									<CommonDropdownForm
										label={t('transcription.import.formatLabel')}
										placeholder={t('transcription.form.languageSelect')}
										fieldName='format'
										options={importFormats}

									/>
								)}
							</div>
							<div className='transcription-import__sources'>
								<Button
									variant='link'
									onClick={() => setModalVisible(true)}
									className={`${styles["transcription-import__sources"]}`}
								>
									{t('transcription.import.checkList')}
								</Button>
							</div>

							<div className={`block__form--row ${styles["transcription-import__input"]}`}>
								<FormInputCustom
									register={register}
									label={t('transcription.import.urlLabel')}
									name='resourceUrl'
									validateData={{
										required: {
											value: true,
											message: t('form.messages.emptyUrl'),
										},
										pattern: { // eslint-disable-line
											value: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/, // eslint-disable-line
											message: t('form.messages.invalidUrl'),
										},
									}}
									errorMessage={errors.resourceUrl ? errors.resourceUrl.message : ''}
									isRequired
								/>
							</div>
							<p className='transcription-import__after-import-comment'>{t('transcription.import.afterImportComment')}</p>
							<div className='transcription-import__doubts--wrapper'>
								{t('transcription.import.doubtsComment')}
								<Link
									rel='noreferrer'
									to={{ pathname: "https://wiki.bu.uni.wroc.pl/index.php/Import_publikacji_z_zewn%C4%99trznych_serwis%C3%B3w" }}
									target='_blank'
								>
									<Button
										variant='link'
										className={`${styles["transcription-import__sources"]}`}
									>
										{t('transcription.import.doubtsLink')}
									</Button>
								</Link>

							</div>
							<div className='transcription-new__form--btns'>
								<Link
									to={url.transcription.new}
								>
									<Button
										variant='secondary'
										className='transcription-new__form--cancel'
									>{t('form.cancel')}
									</Button>
								</Link>
								<FormSubmit
									label={t('transcription.import.startImport')}
									className='transcription-new__form--submit'
								/>
							</div>
						</FormProvider>
					</form>

				</div>
			</div>
		</main >
	);
};

export default TranscriptionImport;
