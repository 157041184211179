import React from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import url from "@/router/urls";

import HeaderMyAccount from "@Templates/header/AppHeader/HeaderMyAccount";

import styles from "@Templates/header/AppHeader/HeaderTopBar.module.sass";
import appHeaderStyles from '@Templates/header/AppHeader/AppHeader.module.sass';

const TopBarMenuRow = () => {
	const { t } = useTranslation();

	return (
		<Row className={styles["top-bar__row--menu"]}>
			<div className={styles["top-bar__item"]}>
				<Dropdown align='end'>
					<Dropdown.Toggle
						id='dropdown-custom-11'
						className={appHeaderStyles["header-dropdown__button"]}
						variant={appHeaderStyles["header-dropdown__button"]}
					>{t('headers.menu.projects')}
					</Dropdown.Toggle>

					<Dropdown.Menu className={appHeaderStyles["header-dropdown__menu"]}>
						<Link
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							to={{ pathname: url.transcriptionsUser, state: { isProtected: true } }}
						>{t('headers.menu.myProjects')}
						</Link>
						<Link
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							to={{ pathname: url.transcriptions, state: { isProtected: false } }}
						>{t('headers.menu.allProjects')}
						</Link>
						<Link
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							to={{ pathname: url.transcription.new, state: { isProtected: true } }}
						>{t('headers.menu.newProject')}
						</Link>
					</Dropdown.Menu>
				</Dropdown>
			</div>

			<div className={styles["top-bar__item"]}>
				<Dropdown align='end'>
					<Dropdown.Toggle
						id='dropdown-custom-11'
						className={appHeaderStyles["header-dropdown__button"]}
						variant={appHeaderStyles["header-dropdown__button"]}
					>{t('events.events')}
					</Dropdown.Toggle>

					<Dropdown.Menu className={appHeaderStyles["header-dropdown__menu"]}>
						<Link
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							to={{ pathname: url.events }}
						>{t('events.allEvents')}
						</Link>
						<Link
							className={`dropdown-item ${appHeaderStyles["header-dropdown__item"]}`}
							to={{ pathname: url.myEvents }}
						>{t('events.myEvents')}
						</Link>
					</Dropdown.Menu>
				</Dropdown>
			</div>

			<div className={styles["top-bar__item"]}>
				<HeaderMyAccount />
			</div>
		</Row>
	);
};

export default TopBarMenuRow;