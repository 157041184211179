import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { reverse } from "named-urls";
import PropTypes from "prop-types";

import styles from "@Pages/Transcription/TranscriptionIndexes/TranscriptionIndexes.module.sass";

import url from '@/router/urls';

function TranscriptionIndexesItem({ index, transcriptionId }) {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	return (
		<>
			<div className={styles["indexes__list--item-name"]} >
				{index.name}
			</div>
			<div
				className={styles["indexes__list--item-collapsebt"]}
				onClick={() => setExpanded(state => !state)}
			>
				{expanded ? 
					<>{t('transcription.indexes.collapseBt')} <i className='icon-chevron-up' /></> :
					<>{t('transcription.indexes.expandBt')} <i className='icon-chevron-down' /></>}
			</div>
			<div className={styles["indexes__list--item-pages"]} >
				{index.type == "term" && <p style={{ width: '100%', fontSize: '1rem' }}>{index.description}</p>}

				{Array.from(index.countedPages).map(([id, page]) => {
					const link = reverse(url.transcription.viewer, { transcriptionId: transcriptionId, pageId: page.id });
					return (
						<Link
							key={`pagelink${page.id}`}
							to={link}
							className={expanded ? styles["indexes__list--item-link"] : styles["indexes__list--item-link-hideonmobile"]}
						>
							{`Plik ${page.pageNo}`}
							<span className={styles["indexes__list--item-count"]}>
								({page.count});
							</span>
						</Link>
					);
				})}
			</div>
		</>
	);
}

TranscriptionIndexesItem.propTypes = {
	index: PropTypes.object,
	transcriptionId: PropTypes.number,
};

export default TranscriptionIndexesItem;
