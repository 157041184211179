import React from 'react';
import PropTypes from 'prop-types';
import styles from '@Components/Events/Tile/TileEvent.module.sass';
import LinkButton from "@Elements/Button/LinkButton";
import Divider from "@Components/Divider/Divider";
import Timeline from '@Components/Events/Timeline/Timeline';
import Blank from  '@Assets/images/empty.jpg';
import { useTranslation } from 'react-i18next';

const TileEventRow = ({event}) => {
	const { t } = useTranslation();
	const {startDate, endDate, name, thumbnailUrl, id} = event;
	return (
		<div className={ styles[`tile-event-row`] }>
			<div className={ styles[`tile-event-row__img`] } style={{backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : `url(${Blank})`}}/>
			<div className={ styles[`tile-event-row__content`] } >
				<div className={ styles[`tile-event-row__infos`] }>
					<p className={ styles[`tile-event-row__content__title`] }>{name}</p>
					<Timeline startDate={startDate} endDate={endDate} type='row'/>
					<Divider />
					<div className={ styles[`tile-event-row__button-container`] }>
						<LinkButton variant='secondary' href={`event/${id}`}>{t('events.buttons.details')}</LinkButton>
					</div>
				</div>
			</div>
		</div>
	);
};

TileEventRow.propTypes = {
	event: PropTypes.shape({
		id: PropTypes.number.isRequired,
		startDate: PropTypes.string,
		endDate: PropTypes.string,
		description: PropTypes.string,
		thumbnailUrl: PropTypes.string,
		name: PropTypes.string,
	}).isRequired,
};

export default TileEventRow;