import React from "react";
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";

import api from "@/services/api";

import Button from '@Elements/Button/Button';

/** 
 * Modal for synchronizing transcription with external object
 * 
 * @component
 */
const ModalRedownloadExternal = ({ handleOnClose, transcriptionId }) => {
	const { t } = useTranslation();

	const redownloadProject = () => {
		api.post(`/imports/${transcriptionId}/redownload`)
			.then(() => {
				window.location.reload(false);
			});
	};

	return (
		<>
			<Modal.Body>
				<p dangerouslySetInnerHTML={ { __html:t('modals.redownloadExternal.body') } } />
			</Modal.Body>
			
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={() => {
						handleOnClose();
					}}
					className='modal__button--cancel'
				>
					{t('form.cancel')}
				</Button>
				<Button
					variant='primary'
					type='submit'
					onClick={() => {
						redownloadProject(transcriptionId);
						handleOnClose();
					}}
					className='modal__button--submit'
				>
					{t('modals.redownloadExternal.button')}
				</Button>
			</Modal.Footer>
		</>
	);
};

ModalRedownloadExternal.propTypes = {
	handleOnClose: PropTypes.func,
	transcriptionId: PropTypes.number,
};

export default ModalRedownloadExternal;
