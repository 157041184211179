import React from "react";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import FormInputCustom from "@Elements/Form/FormInputCustom";

import CommonDropdownForm from '@Elements/Dropdown/CommonDropdownForm';

import AuthorNameRoleArray from '@Pages/Transcription/TranscriptionNew/elements/AuthorNameRoleArray';

const TranscriptionNewMonographicLevel = ({ languageOptions, roleOptions, biblTypes, isDisabled }) => {
	const {
		register,
		getValues,
		formState: {
			errors,
		},
	} = useFormContext();
	const { t } = useTranslation();

	return (
		<div>
			<h4>
				{t('transcription.details.monographicLevel')}
			</h4>
			{!isDisabled &&
			<p className='transcription-new__paragraph'>
				{t('transcription.details.monographicLevelDescription')}
			</p>}
			<FormInputCustom
				register={register}
				label={t('transcription.form.title')}
				name='monographicInfo.title'
				errorMessage={errors.analyticInfo && errors.analyticInfo.title && errors.analyticInfo.title.message ? errors.analyticInfo.title.message : ''}
				isRequired
				infoMessage={isDisabled ? null : t('transcription.new.unknownNameComment')}
				isDisabled={isDisabled}
			/>

			{(!isDisabled || getValues('monographicInfo.biblType') || getValues('monographicInfo.signature')) &&
			<div className='transcription-new__form--inline'>
				{(!isDisabled || getValues('monographicInfo.biblType') !== 'none') &&
				<div className='transcription-new__form--inline-select'>
					<CommonDropdownForm
						label={t('transcription.form.type')}
						placeholder={t('transcription.form.typeSelect')}
						fieldName='monographicInfo.biblType'
						options={biblTypes}
						isDisabled={isDisabled}
					/>
				</div>}

				{(!isDisabled || getValues('monographicInfo.signature')) &&
				<div className='transcription-new__form--inline-full'>
					<FormInputCustom
						register={register}
						label={t('transcription.form.signature')}
						name='monographicInfo.signature'
						isDisabled={isDisabled}
					/>
				</div>}
			</div>}

			<AuthorNameRoleArray
				linkedObject='monographicInfo.actors'
				isDisabled={isDisabled}
				roleOptions={roleOptions}
			/>

			{(!isDisabled || getValues('monographicInfo.language') !== 'none') &&
			<div className='transcription-new__form--inline'>
				<div className='transcription-new__form--inline-select'>
					<CommonDropdownForm
						label={t('transcription.form.language')}
						placeholder={t('transcription.form.languageSelect')}
						fieldName='monographicInfo.language'
						options={languageOptions}
						isDisabled={isDisabled}
					/>
				</div>
			</div>}

			{(!isDisabled || getValues('monographicInfo.chapterNo') || getValues('monographicInfo.volumeNo') || getValues('monographicInfo.issueNo')) &&
			<div className='transcription-new__form--inline no-column-mobile'>
				{(!isDisabled || getValues('monographicInfo.chapterNo')) &&
				<FormInputCustom
					register={register}
					label={t('transcription.form.chapterNumber')}
					name='monographicInfo.chapterNo'
					inputContainerClassName='transcription-new__form--row-narrow'
					type='number'
					isDisabled={isDisabled}
				/>}

				{(!isDisabled || getValues('monographicInfo.volumeNo')) &&
				<FormInputCustom
					register={register}
					label={t('transcription.form.volumeNumber')}
					name='monographicInfo.volumeNo'
					inputContainerClassName='transcription-new__form--row-narrow'
					type='number'
					isDisabled={isDisabled}
				/>}

				{(!isDisabled || getValues('monographicInfo.issueNo')) &&
				<FormInputCustom
					register={register}
					label={t('transcription.form.notebookNumber')}
					name='monographicInfo.issueNo'
					inputContainerClassName='transcription-new__form--row-narrow'
					type='number'
					isDisabled={isDisabled}
				/>}
			</div>}

			{(!isDisabled || getValues('monographicInfo.pageRange') || getValues('monographicInfo.pages')) &&
			<div className='transcription-new__form--inline  no-column-mobile'>
				{(!isDisabled || getValues('monographicInfo.pageRange')) &&
				<FormInputCustom
					register={register}
					label={t('transcription.form.pagesRange')}
					name='monographicInfo.pageRange'
					placeholder={t('transcription.form.pagesRangePlaceholder')}
					inputContainerClassName='transcription-new__form--row-narrow'
					isDisabled={isDisabled}
				/>}

				{(!isDisabled || getValues('monographicInfo.pages')) &&
				<FormInputCustom
					register={register}
					label={t('transcription.form.pagesNumber')}
					name='monographicInfo.pages'
					type='number'
					inputContainerClassName='transcription-new__form--row-narrow'
					isDisabled={isDisabled}
				/>}
			</div>}

			{(!isDisabled || getValues('monographicInfo.publisher')) &&
			<div className='transcription-new__form--inline'>
				<FormInputCustom
					register={register}
					label={t('transcription.form.publisher')}
					name='monographicInfo.publisher'
					inputContainerClassName='transcription-new__form--row-full'
					isDisabled={isDisabled}
				/>
			</div>}

			{(!isDisabled || getValues('monographicInfo.publicationPlace')) &&
			<div className='transcription-new__form--inline'>
				<FormInputCustom
					register={register}
					label={t('transcription.form.publicationPlace')}
					name='monographicInfo.publicationPlace'
					inputContainerClassName='transcription-new__form--row-full'
					isDisabled={isDisabled}
				/>
			</div>}

			{(!isDisabled || getValues('monographicInfo.publicationDate')) &&
			<div className='transcription-new__form--inline'>
				<FormInputCustom
					register={register}
					label={t('transcription.form.publicationDate')}
					name='monographicInfo.publicationDate'
					inputContainerClassName='transcription-new__form--row-full'
					placeholder={t('transcription.form.publicationDatePlaceholder')}
					isDisabled={isDisabled}
				/>
			</div>}

			{(!isDisabled || getValues('transcription.form.isbn') || getValues('transcription.form.eisbn')) &&
			<div className='transcription-new__form--inline'>
				{(!isDisabled || getValues('monographicInfo.isbn')) &&
				<FormInputCustom
					register={register}
					label={t('transcription.form.isbn')}
					name='monographicInfo.isbn'
					inputContainerClassName='transcription-new__form--row-half'
					isDisabled={isDisabled}
				/>}

				{(!isDisabled || getValues('monographicInfo.eisbn')) &&
				<FormInputCustom
					register={register}
					label={t('transcription.form.eisbn')}
					name='monographicInfo.eisbn'
					inputContainerClassName='transcription-new__form--row-half'
					isDisabled={isDisabled}
				/>}
			</div>}

			{(!isDisabled || getValues('monographicInfo.issn') || getValues('monographicInfo.eissn')) &&
			<div className='transcription-new__form--inline'>
				{(!isDisabled || getValues('monographicInfo.issn')) &&
				<FormInputCustom
					register={register}
					label={t('transcription.form.issn')}
					name='monographicInfo.issn'
					inputContainerClassName='transcription-new__form--row-half'
					isDisabled={isDisabled}
				/>}

				{(!isDisabled || getValues('monographicInfo.eissn')) &&
				<FormInputCustom
					register={register}
					label={t('transcription.form.eissn')}
					name='monographicInfo.eissn'
					inputContainerClassName='transcription-new__form--row-half'
					isDisabled={isDisabled}
				/>}
			</div>}
		</div>
	);
};

TranscriptionNewMonographicLevel.propTypes = {
	languageOptions: PropTypes.array,
	roleOptions: PropTypes.array,
	biblTypes: PropTypes.array,
	isDisabled: PropTypes.bool,
};

export default TranscriptionNewMonographicLevel;