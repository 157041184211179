import React from "react";
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";

import api from "@/services/api";

import Button from '@Elements/Button/Button';

/** 
 * Modal for sending ready transcription for acceptation of being sent to DLibra
 * 
 * @component
 */
const ModalSendToDigiLab = ({ handleOnClose, transcriptionId }) => {
	const { t } = useTranslation();

	const sendProject = () => {
		api.put(`/transcriptions/${transcriptionId}/export/ready`)
			.then(res => {
				api.put(`/transcriptions/${transcriptionId}/export/accept`, { accepted: true, message: "" })
					.then(res => {
						window.location.reload(false);
					});
			});
	};

	return (
		<>
			<Modal.Body>
				<p dangerouslySetInnerHTML={ { __html:t('modals.sendToDigiLab.body') } } />
			</Modal.Body>
			
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={() => {
						handleOnClose();
					}}
					className='modal__button--cancel'
				>
					{t('form.cancel')}
				</Button>
				<Button
					variant='primary'
					type='submit'
					onClick={() => {
						sendProject(transcriptionId);
						handleOnClose();
					}}
					className='modal__button--submit'
				>
					{t('modals.sendToDigiLab.button')}
				</Button>
			</Modal.Footer>
		</>
	);
};

ModalSendToDigiLab.propTypes = {
	handleOnClose: PropTypes.func,
	transcriptionId: PropTypes.number,
};

export default ModalSendToDigiLab;