import React from "react";
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Button from '@Elements/Button/Button';
import PropTypes from "prop-types";

import { useForm } from 'react-hook-form';

import FormCheckbox from "@Elements/Form/FormCheckbox";

const ModalDeleteUser = ({ modalProps, onDeleteUserAccepted }) => {
	const { t } = useTranslation();

	const formMethods = useForm(
		{
			mode: 'onSubmit',
			reValidateMode: 'onChange',
			defaultValues: {
				removePublished: false,
			},
		},
	);

	const { handleSubmit, register, formState: { errors } } = formMethods;

	const onSubmit = ({removePublished}) => {
		onDeleteUserAccepted(removePublished);
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
		>
			<Modal.Body>
				<p dangerouslySetInnerHTML={{ __html: t('auth.account.deleteAccountComment')}} />
				<div style={{marginTop: '16px'}}>
					<FormCheckbox
						name='removePublished'
						register={register}
						errorMessage={errors && errors.removePublished && errors.removePublished.message}
					>
						{t('auth.account.removePublishedCheck')}
					</FormCheckbox>

				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<Button
					variant='secondary'
					onClick={() => {
						modalProps.onCloseModal();
					}}
					className='modal__button--cancel'
				>
					{t('form.cancel')}
				</Button>
				<Button
					variant='primary'
					type='submit'
					className='modal__button--submit'
				>
					{t('auth.account.removeAccount')}
				</Button>
			</Modal.Footer>
		</form>
	);
};

ModalDeleteUser.propTypes = {
	modalProps: PropTypes.object,
	onDeleteUserAccepted: PropTypes.func,
};

export default ModalDeleteUser;