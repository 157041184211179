import React from "react";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import FormInputCustom from "@Elements/Form/FormInputCustom";

import FormTextarea from "@Elements/Form/FormTextarea";

const TranscriptionNewTextProfileLevel = ({ isDisabled }) => {
	const { register, getValues } = useFormContext();
	const { t } = useTranslation();

	if (!getValues('profileDesc.keywords') && !getValues('profileDesc.summary'))
		return null;

	return (
		<div className='transcription-new__level'>
			<h4>
				{t('transcription.details.textLevel')}
			</h4>
			{!isDisabled &&
			<p className='transcription-new__paragraph'>
				{t('transcription.details.textLevelDescription')}
			</p>}
			{(!isDisabled || getValues('profileDesc.keywords')) &&
			<div>
				<FormInputCustom
					register={register}
					label={t('transcription.form.keywords')}
					name='profileDesc.keywords'
					placeholder={t('transcription.form.keywordsPlaceholder')}
					isDisabled={isDisabled}
				/>
			</div>}

			{(!isDisabled || getValues('profileDesc.summary')) &&
			<div className='transcription-new__form--textarea-wrapper'>
				<FormTextarea
					name='profileDesc.summary'
					register={register}
					label={t('transcription.form.summary')}
					isDisabled={isDisabled}
				/>
			</div>}
		</div>
	);
};

TranscriptionNewTextProfileLevel.propTypes = {
	isDisabled: PropTypes.bool,
};

export default TranscriptionNewTextProfileLevel;