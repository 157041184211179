import React from "react";
import { useTranslation } from 'react-i18next';
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

import FormInputCustom from "@Elements/Form/FormInputCustom";

import CommonDropdownForm from '@Elements/Dropdown/CommonDropdownForm';

import AuthorNameRoleArray from '@Pages/Transcription/TranscriptionNew/elements/AuthorNameRoleArray';

const TranscriptionNewAnalyticalLevel = ({ languageOptions, roleOptions, isDisabled = false }) => {
	const {
		register,
		getValues,
		formState: {
			errors,
		},
	} = useFormContext();
	const { t } = useTranslation();

	return (
		<div>
			<h4>
				{t('transcription.details.analyticLevel')}
			</h4>
			{!isDisabled &&
			<p className='transcription-new__paragraph'>
				{t('transcription.details.analyticLevelDescription')}
			</p>}

			<FormInputCustom
				register={register}
				label={t('transcription.form.title')}
				name='analyticInfo.title'
				validateData={
					{
						validate: value => {
							if (value === '' && getValues('monographicInfo.title') === '') {
								return t('form.messages.minumumOnetitle');
							}
						},
					}
				}
				errorMessage={errors.analyticInfo && errors.analyticInfo.title && errors.analyticInfo.title.message ? errors.analyticInfo.title.message : ''}
				isRequired
				infoMessage={isDisabled ? null : t('transcription.new.unknownNameComment')}
				isDisabled={isDisabled}
			/>

			<AuthorNameRoleArray
				linkedObject='analyticInfo.actors'
				isDisabled={isDisabled}
				roleOptions={roleOptions}
			/>

			{(!isDisabled || getValues('analyticInfo.language') !== 'none') &&
			<div className='transcription-new__form--inline'>
				<div className='transcription-new__form--inline-select'>
					<CommonDropdownForm
						label={t('transcription.form.language')}
						placeholder={t('transcription.form.languageSelect')}
						fieldName='analyticInfo.language'
						options={languageOptions}
						isDisabled={isDisabled}
					/>
				</div>
			</div>}
		</div>
	);
};

TranscriptionNewAnalyticalLevel.propTypes = {
	languageOptions: PropTypes.array,
	roleOptions: PropTypes.array,
	isDisabled: PropTypes.bool,
};

export default TranscriptionNewAnalyticalLevel;