import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
import { reverse } from "named-urls";
import { useHistory, Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import api from "@/services/api";
import url from "@/router/urls";

import { appActions } from "@/store/actions/app.actions";

import { CheckViewModePermission } from "@/permissions/ViewModes";

import Button from "@Elements/Button/Button";
import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import Alert from "@Elements/Alert/Alert";

import TranscriptionBackLink from "@Pages/Transcription/parts/TranscriptionBackLink";

import SearchTranscriptions from "@Elements/Search/SearchTranscriptions";
import SearchTypeEnum from "@/utils/SearchTypeEnum";

import styles from "@Pages/Transcription/TranscriptionLayers/TranscriptionLayers.module.sass";

import ModalEditLayer from '@Elements/Modal/parts/LayersActions/ModalEditLayer';
import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";

import ModalBaseCancelConfirm from '@Elements/Modal/ModalBaseCancelConfirm';

const TranscriptionLayers = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);
	const { transcriptionId } = useParams();
	const history = useHistory();
	const [layers, setLayers] = useState([]);
	const [useMetadata, setUseMetadata] = useState(SearchTypeEnum.ALL.toString());
	const [viewMode, setViewMode] = useState(null);

	const [title, setTitle] = useState("");
	const [dataLoaded, setDataLoaded] = useState(false);
	const [summarizeData, setSummarizeData] = useState([]);
	const [searchString, setSearchString] = useState("");
	const [layerToDelete, setLayerToDelete] = useState(null);
	const [layerToEdit, setLayerToEdit] = useState(null);

	const linkToTranscriptionDetails = reverse(url.transcription.details, { transcriptionId: transcriptionId });

	useEffect(() => {
		reloadData();
	}, [isLoggedIn]);

	const reloadData = () => {
		dispatch(appActions.setLoading(true));
		getTranscriptionLayersData();
		getTranscriptionSummarizeData();
	};

	const getTranscriptionLayersData = () => {
		api.get(`/transcriptions/${transcriptionId}/layer`)
			.then(({ data }) => {
				setLayers(data);
			})
			.catch(() => {
				history.push(linkToTranscriptionDetails);
			});
	};

	const getTranscriptionSummarizeData = () => {
		api.get(`/transcriptions/${transcriptionId}`).then(({ data }) => {
			setViewMode(data.viewMode);
			setSummarizeData(data);
			setTitle(data.titleLabel);
			dispatch(appActions.setLoading(false));
			setDataLoaded(true);
		});
	};

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: t('breadcrumbs.transcriptions'), link: url.transcriptions },
		{ id: 3, label: title, link: linkToTranscriptionDetails },
		{ id: 4, label: t('breadcrumbs.layers') },
	];

	const putEditedLayer = async (layer) => {
		dispatch(appActions.setLoading(true));
		try {
			await api.put(`/transcriptions/${transcriptionId}/layer/${layer.id}`, {
				...layer,
				transcriptionId: transcriptionId,
			});
		} catch (err) {
			// eslint-disable-next-line
			console.log(err);
		} finally {
			setLayerToEdit(null);
			reloadData();
		}
	};

	const deleteLayerConfirmed = async () => {
		dispatch(appActions.setLoading(true));
		await api.delete(`/transcriptions/${transcriptionId}/layer/${layerToDelete.id}`);
		setLayerToDelete(null);
		reloadData();
		dispatch(appActions.setLoading(false));
	};

	return (
		<>
			{/* EDIT LAYER MODAL */}
			<ModalWrapperChildren
				modalVisible={!!layerToEdit}
				setModalVisible={() => setLayerToEdit(null)}
				title={t('modals.editTranscriptionLayer.title')}
			>
				<ModalEditLayer
					handleOnClose={() => setLayerToEdit(null)}
					layer={layerToEdit}
					onEditComplete={putEditedLayer}
				/>
			</ModalWrapperChildren>

			{/* DELETE LAYER MODAL */}
			<ModalBaseCancelConfirm
				modalVisible={!!layerToDelete}
				setModalVisible={() => setLayerToDelete(null)}
				title={t('modals.deleteTranscriptionLayer.title')}
				bodyHTML={t('modals.deleteTranscriptionLayer.bodyHTML')}
				cancelButtonName={t('form.cancel')}
				confirmButtonName={t('modals.deleteTranscriptionLayer.button')}
				onConfirm={deleteLayerConfirmed}
			/>

			{dataLoaded && (
				<main className={styles["layers__container"]}>
					<SearchTranscriptions
						setSearchString={setSearchString}
						searchString={searchString}
						setUseMetadata={setUseMetadata}
					/>
					<Container>
						<Breadcrumbs breadcrumbsList={breadcrumbsList} />
						<div className={styles["layers__separator"]} />
						<TranscriptionBackLink transcriptionId={transcriptionId} />
						<Alert />
						<h2 className={styles['layers__title']}>{t('transcription.layers.title')}</h2>
						<div className={styles["layers__separator"]} />
						<div className={styles["layers__status"]}>
							<p>{t('transcriptions.description.name')} <span>{summarizeData.titleLabel}</span></p>
							<p>{t('transcriptions.description.projectAuthor')}<span>{summarizeData?.creator?.email || ''}</span></p>
							<p>{t('transcription.layers.description')}</p>
						</div>

						<div className={styles['layers__table']}>
							<div className={styles['layers__table--header']}>
								{t('breadcrumbs.layers')} ({layers.length})
							</div>

							<div className={`${styles["layers__table--caption"]} ${styles["layers__table--row__readOnly"]}`}>
								<div className={styles['layers__table--column']}>{t('transcriptions.layers.no')}</div>
								<div className={styles['layers__table--column']}>{t('transcriptions.layers.name')}</div>
								<div className={styles['layers__table--column']}>{t('transcriptions.layers.tableContentType')}</div>
								<div className={styles['layers__table--column']}>{t('transcriptions.layers.desc')}</div>
							</div>

							{layers.length && layers.map((layer, index) => (
								<div key={index} className={`${CheckViewModePermission("canOperateOnTranscriptionLayers", viewMode) ? styles["layers__table--row"] : styles["layers__table--row__readOnly"]} `}>
									<div className={styles['layers__table--column']}>
										<div className={styles['layers__table--mobile-description']}>{t('transcriptions.layers.tableNumber')}</div>
										<div>{index + 1}</div>
									</div>
									<div className={styles['layers__table--column']}>
										<div className={styles['layers__table--mobile-description']}>{t('transcriptions.layers.tableName')}</div>
										<div className={styles["layers__table--item-name"]}>{layer.name}</div>
									</div>
									<div className={styles['layers__table--column']}>
										<div className={styles['layers__table--mobile-description']}>{t('transcriptions.layers.tableContentType')}</div>
										<div className={styles["layers__table--item-name"]}>{layer.type === null ? t('transcriptions.layers.typeText') : t('transcriptions.layers.typeAnotations')}</div>
									</div>
									<div className={styles['layers__table--column']}>
										<div className={styles['layers__table--mobile-description']}>{t('transcriptions.layers.tableDescription')}</div>
										<div>{layer.description}</div>
									</div>
									{CheckViewModePermission("canOperateOnTranscriptionLayers", viewMode) &&
										<div className={styles['layers__table--bt-column']}>
											{layers?.length > 1 &&
												<Button
													variant='link'
													onClick={() => setLayerToDelete(layer)}
													className={styles['layer-delete-bt']}
												>
													{t('transcriptions.layers.delete')}
												</Button>}

											<Button
												variant='secondary'
												onClick={() => setLayerToEdit(layer)}
												className={styles['layer-edit-bt']}
											>
												{t('transcriptions.layers.edit')}
											</Button>
										</div>}
								</div>
							))}
							{CheckViewModePermission("canOperateOnTranscriptionLayers", viewMode) &&
								<Link
									disabled={layers.length > 29}
									to={reverse(url.transcription.addLayer, { transcriptionId: transcriptionId })}
								>
									<Button
										variant='primary'
										type='button'
										disabled={layers.length > 29}
										className={styles['layers__new-layer-bt']}
									>
										{t('transcriptions.layers.addNew')}
									</Button>
								</Link>}
						</div>

					</Container>
				</main >
			)}
		</>
	);
};

export default TranscriptionLayers;
