import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import api from "@/services/api";
import Form from 'react-bootstrap/Form';

import { useTranslation } from 'react-i18next';
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";

import FormSubmit from "@Elements/Form/FormSubmit";
import FormInputCustom from "@Elements/Form/FormInputCustom";

import styles from "@Pages/Event/Event.module.sass";

const AddUser = ({eventId, getEvent, assignedTeams, dataToSend, setTeams}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const defaultValues = {
		userEmail: "",
		team: "",
	};
  
	const {
		register,
		handleSubmit,
		formState: {
			errors,
		},
		setValue,
		setError,
		reset,
	} = useForm({ defaultValues: defaultValues });
  
	const addUser = async (values) => {
		const users = dataToSend.reduce((result, team) => {
			team.memberEmails.map(email => result.push(email));
			return result;
		}, []);
		const toAdd = values.userEmail.match(/[^,\s]+/g).filter(email => !users.includes(email));

		if (toAdd.length > 0) {
			try {
				const res = await api.put(`/events/${eventId}/attendees`, toAdd);

				if (res.status === 200) {
					const data = dataToSend.find(team => team.name === values.team);
					data.memberEmails.push(...toAdd);
					const dataFinal = [
						...dataToSend.filter(team => team.name && team.name != values.team),  
						data,
					];
  
					try {
						const resTeams = await api.put(`/events/${eventId}/teams`, dataFinal);
						if (resTeams.status === 200) {
							dispatch(appActions.setLoading(false));
							setTeams(resTeams.data);
							getEvent();
							reset({ userEmail: "" });
						}
					} catch (error) {
						dispatch(appActions.setLoading(false));
						setError("userEmail", {
							type: "manual",
							message: t('events.teams.addUser.errors.general'),
						});
					}
				}
			} catch (error) {
				dispatch(appActions.setLoading(false));
				if (error.status === 400 && error.data.email === "noSuchUser") { 
					setError("userEmail", {
						type: "manual",
						message: t('events.teams.addUser.errors.userNotExist'),
					});
				} else {
					setError("userEmail", {
						type: "manual",
						message: t('events.teams.addUser.errors.general'),
					});
				}
			}
		} else {
			setError("userEmail", {
				type: "manual",
				message: t('events.teams.addUser.errors.userExist'),
			});
		}
		
	};

	useEffect(() => {
		if(assignedTeams[0]?.name) {
			setValue("team", assignedTeams[0].name);
		}
	}, [assignedTeams]);

	const emailRegex = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
	const emailsRegex = new RegExp(`^(${emailRegex.source})([,\\s]+${emailRegex.source})*$`);

	return (
		<div className={ styles["teams__add-user"] }>
			<form onSubmit={handleSubmit(addUser)}>
				<p className={ styles["teams__add-user__title"] }>{t('events.teams.addUser.title')}</p>
				<div className={ styles["teams__add-user__input"] }>
					<Form.Group controlId='userEmail'>
						<FormInputCustom
							register={register}
							errorMessage={errors?.userEmail?.message}
							isRequired
							label={t('events.teams.addUser.label')}
							name='userEmail'
							infoMessage={t('events.teams.addUser.info')}
							validateData={{
								required: {
									value: true,
									message: t('form.messages.emptyEmail'),
								},
								pattern: {
									value: emailsRegex,
									message: t('form.messages.invalidEmail'),
								},
							}}
						/>
					</Form.Group>
				</div>
				<div className={ styles["teams__add-user__select-container"] }>
					<select className={ styles["teams__add-user__select"] } {...register("team")}>
						{(assignedTeams && assignedTeams.length > 0) && assignedTeams.map((team) => (<option key={team.name} value={team.name}>{team.name} </option>)) }
					</select>
				</div>
				<p className={ styles["teams__add-user__text-required"] }>{t('events.teams.addUser.required')}</p>
				<div className={ styles["teams__add-user__add-container"] }>
					<FormSubmit
						className={ styles["teams__add-user__add-button"] }
						label={t('events.teams.addUser.buttons.addUser')}
						variant='submit'
					/>
				</div>
			</form>
		</div>
	);
};

AddUser.propTypes = {
	eventId: PropTypes.string, 
	getTeams: PropTypes.func, 
	getEvent: PropTypes.func, 
	assignedTeams:  PropTypes.array,
	dataToSend: PropTypes.array, 
	setTeams: PropTypes.func,
};

export default AddUser;