import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { reverse } from "named-urls";

import FormInputCustom from "@Elements/Form/FormInputCustom";

import FormRadioGroup from '@Elements/Form/FormRadioGroup';

import url from "@/router/urls";
import api from "@/services/api";

import { appActions } from '@/store/actions/app.actions';
import { alertActions } from "@/store/actions/alert.actions";

import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import Alert from "@Elements/Alert/Alert";
import Button from "@Elements/Button/Button";

import SearchTranscriptions from "@Elements/Search/SearchTranscriptions";
import SearchTypeEnum from "@/utils/SearchTypeEnum";

import styles from "@Pages/Transcription/TranscriptionAddLayer/TranscriptionAddLayer.module.sass";

const TranscriptionAddLayer = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { transcriptionId } = useParams();

	const dispatch = useDispatch();

	const [title, setTitle] = useState('');
	const [searchString, setSearchString] = useState(null);
	const [useMetadata, setUseMetadata] = useState(SearchTypeEnum.ALL.toString());

	const breadcrumbsList = [
		{ id: 1, label: "home", link: url.home },
		{ id: 2, label: t('breadcrumbs.transcriptions'), link: url.transcriptions },
		{ id: 3, label: title, link: reverse(url.transcription.details, { transcriptionId: transcriptionId }) },
		{ id: 4, label: t('breadcrumbs.layers'), link: reverse(url.transcription.layers, { transcriptionId: transcriptionId }) },
		{ id: 5, label: t('transcriptions.layers.addNew')},
	];

	const {
		register,
		handleSubmit,
		watch,
		formState: {
			errors, isValid,
		},
	} = useForm({
		defaultValues: {
			layerName: '',
			layerDescription: '',
			layerType: 'null',
			copyVerses: '1',
			copyBlocks: '1',
		},
		mode: "onChange",
	});
	const watchCopyVerses = watch('copyVerses');
	const watchLayerType = watch('layerType');

	useEffect(() => {
		getTranscriptionData(transcriptionId);
	}, [transcriptionId]);

	const parseCopyType = (versesOption, blocksOption) => {
		if (versesOption === 2) {
			if (blocksOption === 1) return 'VERSES_EMPTY';
			if (blocksOption === 2) return 'BLOCKS_EMPTY';
		}
		if (versesOption === 3) {
			if (blocksOption === 1) return 'VERSES';
			if (blocksOption === 2) return 'BLOCKS';
		}
		return 'NONE';
	};

	const getTranscriptionData = useCallback(async (transId) => {
		const {data: {titleLabel}} = await api.get(`/transcriptions/${transId}`);
		setTitle(titleLabel);
	}, []);

	const onSubmit = async (data) => {
		dispatch(appActions.setLoading(true));
		const dataToSend = {
			name: data.layerName,
			description: data.layerDescription,
			type: data.layerType === 'null' ? null : data.layerType,
			...(data.layerType === 'null' && { copy: parseCopyType(parseInt(data.copyVerses), parseInt(data.copyBlocks)) }),
		};
		try {
			await api.post(`/transcriptions/${transcriptionId}/layer`, dataToSend);
			setSuccessToast();
		} catch (err) {
			// eslint-disable-next-line
			console.log('transcriptionAddLayer ERROR: ', err);
		} finally {
			dispatch(appActions.setLoading(false));
		}
		history.push(reverse(url.transcription.layers, { transcriptionId: transcriptionId }));
	};

	const setSuccessToast = () => {
		dispatch(
			alertActions.setAlert({
				type: "success",
				icon: "icon-circle-accept",
				text: t('transcriptions.addLayerPage.layerAddedToast'),
				close: true,
			}),
		);
	};

	return (
		<main>
			<SearchTranscriptions
				setSearchString={setSearchString}
				searchString={searchString}
				setUseMetadata={setUseMetadata}
			/>
			<Container>
				<Breadcrumbs breadcrumbsList={breadcrumbsList} />
				<div className={styles['add-layer__container']}>
					<Alert />

					<h2 className={styles['add-layer__header--title']}>
						{t('transcriptions.addLayerPage.title')}
					</h2>
					<form
						onSubmit={handleSubmit(onSubmit)}
					>
						<p className={styles['add-layer__paragraph']}>{t('transcriptions.addLayerPage.addLayerStep1')}</p>
						<FormInputCustom
							register={register}
							label={t('transcriptions.addLayerPage.layerName')}
							name='layerName'
							validateData={{
								required: {
									value: true,
									message: t('form.messages.fillField'),
								},
							}}
							errorMessage={errors.layerName ? errors.layerName.message : ''}
							isRequired
							inputContainerClassName={styles['add-layer__block--small']}
						/>
						<FormInputCustom
							register={register}
							label={t('transcriptions.addLayerPage.layerDescription')}
							name='layerDescription'
							validateData={{
								required: {
									value: true,
									message: t('form.messages.fillField'),
								},
							}}
							errorMessage={errors.layerName ? errors.layerName.message : ''}
							infoMessage={t('transcriptions.addLayerPage.descriptionInfo')}
							isRequired
							inputContainerClassName={styles['add-layer__block--small']}
						/>

						<FormRadioGroup
							label={t('transcriptions.addLayerPage.addLayerStep2')}
							name='layerType'
							register={register}
							options={[
								{ label: t('transcriptions.addLayerPage.step2option1'), value: 'null' },
								{ label: t('transcriptions.addLayerPage.step2option2'), value: 'IIIF_ANNOTATION' },
							]}
							errorMessage={errors.layerType ? errors.layerType.message : ''}
							className={styles['add-layer__block']}
							labelClassName={styles['add-layer__block--label']}
						/>

						<FormRadioGroup
							label={t('transcriptions.addLayerPage.addLayerStep3')}
							name='copyVerses'
							register={register}
							options={[
								{ label: t('transcriptions.addLayerPage.step3option1'), value: '1' },
								{ label: t('transcriptions.addLayerPage.step3option2'), value: '2' },
								{ label: t('transcriptions.addLayerPage.step3option3'), value: '3' },
							]}
							errorMessage={errors.copyVerses ? errors.copyVerses.message : ''}
							disabled={watchLayerType !== 'null'}
							className={styles['add-layer__block']}
							labelClassName={styles['add-layer__block--label']}
						/>

						<FormRadioGroup
							label={t('transcriptions.addLayerPage.addLayerStep4')}
							name='copyBlocks'
							register={register}
							options={[
								{ label: t('transcriptions.addLayerPage.step4option1'), value: '1' },
								{ label: t('transcriptions.addLayerPage.step4option2'), value: '2' },
							]}
							errorMessage={errors.copyBlocks ? errors.copyBlocks.message : ''}
							disabled={watchCopyVerses === '1' || watchLayerType !== 'null'}
							className={styles['add-layer__block']}
							labelClassName={styles['add-layer__block--label']}
						/>

						<div className={styles['add-layer__btn--container']}>
							<Link
								to={reverse(url.transcription.layers, { transcriptionId: transcriptionId })}
							>
								<Button
									variant='secondary'
									className={styles['add-layer__btn--cancel']}
								>{t('form.cancel')}
								</Button>
							</Link>
							<Button								
								className={styles['add-layer__btn--submit']}
								variant='submit'
								type='submit'
								disabled={!isValid}
							>
								{t('transcriptions.addLayerPage.addlayerbt')}
							</Button>
						</div>
					</form>
				</div>
			</Container>
		</main>
	);
};

export default TranscriptionAddLayer;
