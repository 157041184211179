import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import LinkButton from "@Elements/Button/LinkButton";
import Divider from "@Components/Divider/Divider";
import Blank from  '@Assets/images/empty.jpg';
import Timeline from '@Components/Events/Timeline/Timeline';
import { useTranslation } from 'react-i18next';
import draftToHtml from 'draftjs-to-html';

import styles from '@Components/Events/Tile/TileEvent.module.sass';

const TileEvent = ({event, attend}) => {
	const [descriptionMarkup, setDescriptionMarkup] = useState('');

	const { t } = useTranslation();
	const {startDate, endDate, description, name, thumbnailUrl, id} = event;

	const isValidJSON = (data) => {
		try {
			JSON.parse(data);
			return true;
		} catch (err) {
			return false;
		}
	};

	useEffect(() => {
		if(description) {
			const html = isValidJSON(description) ? draftToHtml(JSON.parse(description)) : description;
			setDescriptionMarkup(html);
		}
	}, [description]);

	return (
		<div className={ styles[`tile-event`] }>
			<div className={ styles[`tile-event__img`] } style={{backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : `url(${Blank})`}}/>
			<div className={ styles[`tile-event__content`] } >
				<div>
					<div className={ styles[`tile-event__content__top`]}>
						<p className={ styles[`tile-event__content__title`] }>{name}</p>
						{attend && <div className={ styles[`tile-event__attend`] }><span>{t('events.buttons.participate')}</span></div>}
					</div>
					<Timeline startDate={startDate} endDate={endDate}/>
					
					<Divider />
					<p className={ styles[`tile-event__content__description`]} dangerouslySetInnerHTML={{ __html: descriptionMarkup}} />
					<Divider />
				</div>
				<div className={ styles[`tile-event__button-container`] }>
					<LinkButton variant='secondary' href={`event/${id}`}>{t('events.buttons.details')}</LinkButton>
				</div>
			</div>
		</div>
	);
};

TileEvent.propTypes = {
	attend: PropTypes.bool,
	event: PropTypes.shape({
		id: PropTypes.number.isRequired,
		startDate: PropTypes.string,
		endDate: PropTypes.string,
		description: PropTypes.string,
		thumbnailUrl: PropTypes.string,
		name: PropTypes.string,
	}).isRequired,
};

export default TileEvent;