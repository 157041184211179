import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { PropTypes } from 'prop-types';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import url from "@/router/urls";

import HeaderTopBar from "@Templates/header/AppHeader/HeaderTopBar";
import Spinner from "@Elements/Spinner/Spinner";

import styles from "@Templates/header/AppHeader/AppHeader.module.sass";
import "@Styles/_navbar.sass";

const MOBILE_BREAKPOINT = 992;

const AppHeaderInner = ({ isHomePage }) => {
	const { t } = useTranslation();

	const [collapsed, setCollapsed] = useState(true);

	const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_BREAKPOINT);

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	}, []);

	useEffect(() => {
		setCollapsed(true);
	}, [isMobile]);

	const toggleNavbar = useCallback(() => {
		setCollapsed(!collapsed);
	}, []);

	const updateMedia = useCallback(() => {
		setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
	}, []);

	return (
		<>
			<Spinner stateFromStore />
			<div
				className={isHomePage ? styles["header__home"] : styles["header__subpage"]}
			>
				<div className={`${styles["header"]} main-header`}>
					<div className={`container ${styles["header__inner"]}`}>
						<a href='https://uwr.edu.pl/'>
							<div
								className={`${styles["header__logo"]} uwr-logo`}
							>
								{t('headers.logoAlt')}
							</div>
						</a>
						<Link to={url.home} style={{ "marginRight": "auto" }}>
							<div className={`${styles["header__logo-translab"]} translab-logo`}>Logo</div>
						</Link>

						<HeaderTopBar
							collapsed={collapsed}
							setCollapsed={setCollapsed}
							isMobile={isMobile}
						/>

						<button
							type='button'
							aria-label='Toggle navigation'
							className={`${styles["header__hamburger"]} hamburger-bt`}
							onClick={toggleNavbar}
						>
							<i className='icon-hamburger' />
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
AppHeaderInner.propTypes = {
	isHomePage: PropTypes.bool,
};

const AppHeader = () => {
	const location = useLocation();
	const isHomePage = useMemo(() => {
		return location.pathname === '/';
	}, [location.pathname]);
	const appHeaderInner = useMemo(() => <AppHeaderInner isHomePage={isHomePage} />, [isHomePage]);
	return (
		<>{appHeaderInner}</>
	);
};

export default AppHeader;
