import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from "@Pages/Transcription/TranscriptionPermissions/TranscriptionPermissions.module.sass";

const TranscriptionPermissionsUserItemHeader = () => {
	const { t } = useTranslation();

	return (
		<div
			className={ styles["permissions__userlist--caption"] }
		> 
			<div className={styles['permissions__userlist--element']}>{t('admin.dashboard.no')}</div>
			<div className={styles['permissions__userlist--element']}>{t('admin.dashboard.email')}</div>
			<div className={styles['permissions__userlist--element']}>{t('transcription.permissions.table.role')}</div>
			<div className={styles['permissions__userlist--element']}>{t('transcription.permissions.table.chars')}</div>
			<div className={styles['permissions__userlist--element']}>{t('transcription.permissions.table.words')}</div>
			<div className={styles['permissions__userlist--element']}>{t('transcription.permissions.table.verses')}</div>
			<div className={styles['permissions__userlist--element']}>{t('transcription.permissions.table.correctVerses')}</div>
			<div/>
		</div>
	);
};

export default TranscriptionPermissionsUserItemHeader;