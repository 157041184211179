import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import api from "@/services/api";
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alertActions } from "@/store/actions/alert.actions";

import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import styles from "@Pages/Event/Event.module.sass";

const ModalAddUsersTeam = ({modalVisible, setModalVisible, attendees, eventId, teamForModal, setTeams, getEvent, dataToSend}) => {
	const [usersIdsToAdd, setUsersIdsToAdd] = useState([]);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const addremoveUserId = (e, userEmail) => {
		if(e.target.checked) {
			setUsersIdsToAdd([...usersIdsToAdd, userEmail]);
		} else if (!e.target.checked) {
			setUsersIdsToAdd(usersIdsToAdd.filter(name => name != userEmail));
		}
	};
    
	const findIdsToAdd = () => {
		const users = attendees.reduce((result, attendee) => {
			if(attendee.team === teamForModal) {
				attendee.users.map(user => {
					result.push(user.name);
				});	
			}
			return result;
		}, []);

		setUsersIdsToAdd(users);
	};
    
	const addUsersToTeam = async () => {
		dispatch(appActions.setLoading(true));
		const data = dataToSend.map(obj => {
			
			if(obj.name === teamForModal) {
				return {
					...obj,
					memberEmails: usersIdsToAdd,
				};
			} else {
				return {
					...obj,
					memberEmails: obj.memberEmails.filter(email => !usersIdsToAdd.includes(email)),
				};
			}
		}).filter(team => team.name);
		
		try {
			const res = await api.put(`/events/${eventId}/teams`, data);

			if (res.status === 200) {
				dispatch(appActions.setLoading(false));
				setTeams(res.data);
				getEvent();
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.teams.addUsersTeam.warning'),
				close: true,
			}));
		}
	};	

	useEffect(() => {
		findIdsToAdd();
	}, [teamForModal, attendees]);

	return (
		<ModalWrapperChildren
			modalVisible={modalVisible}
			closeButtonName={t('events.teams.addUsersTeam.cancel')}
			setModalVisible={() => setModalVisible(false)}
			confirmButtonName={t('events.teams.addUsersTeam.close')}
			onConfirm={() => {addUsersToTeam(), setModalVisible(false);}}
			withFooter
		>
			<div className={ styles["teams__add-users"] }>
				<p className={ styles["teams__add-users__team"] }>{teamForModal}</p>
				<p className={ styles["teams__add-users__title"] }>{t('events.teams.addUsersTeam.assigneDeleteUsers')}</p>
				<div className={ styles["teams__add-users__list"] }>
					{attendees.map(users => {
						return users.users.map(user => {
							return (
								<div key={user.id} className={ styles["teams__add-users__checkbox-container"] }>
									<input className={ styles["teams__add-users__checkbox"] } type='checkbox' checked={usersIdsToAdd.includes(user.name)} onChange={(e) => addremoveUserId(e, user.name)}/>
									<div >{user.name}</div>
								</div>);
						});
					})}
				</div>
			</div>	
		</ModalWrapperChildren>
	);
};

ModalAddUsersTeam.propTypes = {
	modalVisible: PropTypes.bool, 
	setModalVisible: PropTypes.func, 
	eventId: PropTypes.string,
	teamForModal: PropTypes.string, 
	setTeams: PropTypes.func, 
	getEvent: PropTypes.func,
	dataToSend: PropTypes.array,
	attendees: PropTypes.array,
};

export default ModalAddUsersTeam;