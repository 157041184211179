import { reverse } from 'named-urls';

import url from "@/router/urls";

const updateUrl = (history, eventId, pageId, q) => {
	let targetUrl = reverse(url.eventViewer, { eventId: parseInt(eventId), pageId: parseInt(pageId) });
	if(q && q != ""){
		targetUrl += "?q=" + q;
	}
	history.push(targetUrl);
};

export default updateUrl;