import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";

import api from "@/services/api";

const ModalAvailableImportSources = ({ modalProps, selectedImportFormat }) => {
	const { t } = useTranslation();

	const [availableDomains, setAvailableDomains] = useState([]);

	useEffect(() => {
		api.get(`/domains?file-type=${selectedImportFormat.value}`)
			.then(response => {
				setAvailableDomains(response.data);
			});
	}, [selectedImportFormat]);

	return (
		<>
			<Modal.Body>
				{modalProps.modalSpinner && !availableDomains && <modalProps.modalSpinner />}
				<ul className='modal__resources--list'>
					{availableDomains &&
						availableDomains.map((item, i) =>
							<li
								key={item + parseInt(i)}
								className='modal__resources--list-item'
							>
								{item}
							</li>,
						)}
				</ul>
			</Modal.Body>
		</>
	);
};

ModalAvailableImportSources.propTypes = {
	modalProps: PropTypes.object,
	selectedImportFormat: PropTypes.object,
};

export default ModalAvailableImportSources;