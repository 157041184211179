import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import api from "@/services/api";

import Button from '@Elements/Button/Button';
import FormInputCustom from "@Elements/Form/FormInputCustom";

import styles from '@Pages/Auth/Group/Group.module.sass';

const GroupUserAdd = ({ groupId, onAddUserProps }) => {
	const { t } = useTranslation();
	const [isSendingEmail, setIsSendingEmail] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
		reset,
		setError,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			email: "",
		},
	});

	const onAddUser = (data) => {
		setIsSendingEmail(true);
		api.post(`/users-groups/${groupId}`, { email: data.email })
			.then(() => {
				onAddUserProps();
				reset();
				setIsSendingEmail(false);
			}).catch((error) => {
				if (error.data.code === "groups.error.noUserWithGivenEmail") {
					setError('email', 'notFound', t('form.messages.emailNotExists'));
				} else if (error.data.code === "groups.error.userAlreadyExistsInGroup") {
					setError('email', 'notFound', t('form.messages.emailExistsInGroup'));
				}
				setIsSendingEmail(false);
			});
	};

	return (
		<section className={styles["group__add-user"]}>
			<h2 className={styles["group__add-user--header"]}>
				{t('group.addUserHead')}
			</h2>
			<p className={styles["group__add-user--description"]}>
				{t('group.addUserText')}
			</p>
			<form
				className={styles["group__add-user--form"]}
				onSubmit={handleSubmit(onAddUser)}
			>
				<FormInputCustom
					register={register}
					placeholder={t('group.addUserPlaceholder')}
					name='email'
					inputContainerClassName={styles["group__add-user--input"]}
					validateData={{
						pattern: {
							value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
							message: t('form.messages.invalidEmail'),
						},
					}}
					errorMessage={ errors?.email?.message || ''}
				/>
				<Button
					type='submit'
					className={styles["group__add-user--submit"]}
					disabled={isSendingEmail || !isDirty || !isValid}
				>
					{t('group.addToButton')}
				</Button>
				{/* <label className={ `input-group ${styles["group__add-user--tf"]}` }>
					<input
						{...register('email', {
							pattern: {
                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
								message:t('form.messages.invalidEmail'),
							},
						})}
						type='email'
						placeholder={t('group.addUserPlaceholder') }
						className={ 
						`${styles["group__add-user--field"]}
						${errors.email && styles["group__add-user--field-error"]}
						`
						}
					/>
					<div className='input-group-append'>
						<Button 
							type='submit'
							disabled={ isSendingEmail || !formState.isDirty || !formState.isValid }
						>
							{t('group.addToButton')}
						</Button>
					</div>
					{errors.email && (
						<div className={ styles["group__add-user--error"] }>
							<i className='icon-close' />
							{errors.email.message}
						</div>
					)}
				</label> */}
			</form>
		</section>
	);
};

GroupUserAdd.propTypes = {
	groupId: PropTypes.number,
	onAddUserProps: PropTypes.func,
};

export default GroupUserAdd;