import React from "react";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import FormInputCustom from "@Elements/Form/FormInputCustom";

const TranscriptionNewIndentifierLevel = ({ isDisabled }) => {
	const { register, getValues } = useFormContext();
	const { t } = useTranslation();

	return (
		<div className='transcription-new__level'>
			<h4 className='text--level-title'>
				{t('transcription.details.identifierLevel')}
			</h4>
			{(!isDisabled || getValues('externalUrl')) &&
			<div className='transcription-new__form--inline--identifiers'>
				<label className='transcription-new__form--label'>
					{t('transcription.details.importAdress')}
					<span
						className='transcription__tooltip'
						data-tooltip-id='custom-tooltip' data-tooltip-content={t('transcription.details.importAdressDesc') }
						data-place='right'
					>
						<i className='icon-question'  />
					</span>
				</label>
				<FormInputCustom
					register={ register }
					name='externalUrl'
					placeholder={t('transcription.form.keywordsPlaceholder') }
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>
			</div>}

			{(!isDisabled || getValues('externalId')) &&
			<div className='transcription-new__form--inline--identifiers'>
				<label className='transcription-new__form--label'>
					{t('transcription.details.externalId')}
					<span
						className='transcription__tooltip'
						data-tooltip-id='custom-tooltip' data-tooltip-content={t('transcription.details.externalIdDesc') }
						data-place='right'
					>
						<i className='icon-question'  />
					</span>
				</label>
				<FormInputCustom
					register={ register }
					name='externalId'
					placeholder={t('transcription.form.keywordsPlaceholder') }
					inputContainerClassName='transcription-new__form--inline-medium'
					isDisabled={ isDisabled }
				/>
			</div>}

		</div>
	);
};

TranscriptionNewIndentifierLevel.propTypes = {
	isDisabled: PropTypes.bool,
};

export default TranscriptionNewIndentifierLevel;