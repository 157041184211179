import React, {useEffect, useState} from 'react';

import api from "@/services/api";
import url from "@/router/urls";

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { alertActions } from "@/store/actions/alert.actions";
import { appActions } from "@/store/actions/app.actions";
import { Container } from 'react-bootstrap';

import TilesEventRowContainer from '@Components/Events/Tile/TilesEventRowContainer';
import TileEventRow from '@Components/Events/Tile/TileEventRow';
import LinkButton from "@Elements/Button/LinkButton";

import styles from "@Pages/Home/parts/Events/Events.module.sass";

const Events = () => {
	const [ incomingEventsData, setIncomingEventsData] = useState({});

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const getIncomingEvents = async () => { 
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/events?category=ANNOUNCED&page=1&perpage=4`);

			if (res.status === 200) {
				setIncomingEventsData(res.data);
				dispatch(appActions.setLoading(false));
			}

		} catch (
			err
		) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.warnings.getIncomingEvents'),
				close: true,
			}));
		}
	};

	useEffect(() => {
		getIncomingEvents();
	}, []);

	return (
		<>		
			{
				incomingEventsData && incomingEventsData.hits > 0 && 
				<Container>
					<		div className={ styles["events__title-container"] }>
						<p className={ styles["events__title"] }>Nadchodzące rozgrywki</p>
					</div>
					<div>
						{		
							incomingEventsData?.events?.length > 0 && 
							<>
								<	TilesEventRowContainer>
									{incomingEventsData.events.map(event => <TileEventRow type='tile-event-row' key={event.id} event={event}/>)}
								</TilesEventRowContainer>
							</>
						}
					</div>
					<	div className={ styles["events__button-container"] }>
						<LinkButton href={url.events}>Wszystkie rozgrywki</LinkButton>
					</div>
				</ Container>
			}
		</>

	);
};

export default Events;