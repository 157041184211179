import React, { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown, Row, Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import url from "@/router/urls";

import HeaderTopLang from "@Templates/header/AppHeader/HeaderTopLang";

import styles from "@Templates/header/AppHeader/HeaderTopBar.module.sass";

import TopBarMenuRow from "@Templates/header/AppHeader/TopBarMenuRow";
import TopBarMenuRowMobile from "@Templates/header/AppHeader/TopBarMenuRowMobile";

const HeaderTopBar = ({ collapsed, setCollapsed, isMobile }) => {
	const { t } = useTranslation();
	const mobileMenuRef = useRef();

	const handleClickOutside = (ev) => {
		if (mobileMenuRef.current && !mobileMenuRef.current.contains(ev.target)) {
			setCollapsed(true);
		}
	};

	useEffect(() => {
		if (isMobile && !collapsed) {
			document.addEventListener("mousedown", handleClickOutside);
		}
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [collapsed, isMobile]);

	return (
		<div
			className={`${collapsed ? styles["top-bar__collapsed"] : styles["top-bar"]
				}`}
			ref={mobileMenuRef}
		>
			{isMobile && (
				<div className={styles["top-bar__mobile-header"]}>
					<h2>Menu</h2>
					<div onClick={() => setCollapsed(true)} className={styles["top-bar__mobile-header--close-bt"]}>
						<i className='icon-close' />
					</div>
				</div>
			)}
			<Container className={styles["top-bar__container"]}>
				<Row className={styles["top-bar__row"]}>
					{/* <Link
						to={url.howToTranscribe}
						className={styles["top-bar__how-to-transcribe"]}
					>
						<span>{t('transcriptionHelpPage.howToTranscribe.title')}</span>
					</Link> */}
					<Dropdown align='end'>
						<Dropdown.Toggle
							id='dropdown-custom-11'
							className={styles["top-bar-dropdown__button"]}
							variant={styles["top-bar-dropdown__button"]}
						>{t('transcriptionHelpPage.howToTranscribe.title')}
						</Dropdown.Toggle>

						<Dropdown.Menu className={styles["top-bar-dropdown__menu"]}>
							<Link
								className={`dropdown-item ${styles["top-bar-dropdown__item"]}`}
								to={{ pathname: 'https://wiki.bu.uni.wroc.pl/index.php/TransLab' }}
								target='_blank'
								rel='noreferrer'
							>{t('transcriptionHelpPage.howToTranscribe.title')}
							</Link>
							<Link
								className={`dropdown-item ${styles["top-bar-dropdown__item"]}`}
								to={{ pathname: url.contact }}
							>{t('headers.informationsMenu.contact')}
							</Link>
						</Dropdown.Menu>
					</Dropdown>
					<HeaderTopLang isMobile={isMobile} />
					{!isMobile && (
						<div
							className={styles["top-bar__ue-logo"]}
						/>
					)}
				</Row>
				{isMobile ? <TopBarMenuRowMobile /> : <TopBarMenuRow />}
			</Container>
		</div>
	);
};

HeaderTopBar.propTypes = {
	collapsed: PropTypes.bool,
	isMobile: PropTypes.bool,
	setCollapsed: PropTypes.func,
};

export default HeaderTopBar;
