import React from 'react';
import PropTypes from 'prop-types';
import styles from '@Components/Events/Tile/TileEvent.module.sass';

const TilesEventContainer = ({children}) => {
	return (
		<div className={ styles["tile-event__container"] }>
			{children}
		</div>
	);
};

TilesEventContainer.propTypes = {
	children: PropTypes.array,
};

export default TilesEventContainer;