import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CommonDropdown from '@Elements/Dropdown/CommonDropdown';

const TranscriptionsMyScopeSelect = ({ scope, setScope }) => {
	const { t } = useTranslation();

	const [ dropdownOption, setDropdownOption ] = useState(0);
	
	const scopes = [
		{
			value: "creator_or_member",
			label:t('transcriptions.scope.myAll'),
		},
		{
			value: "creator",
			label:t('transcriptions.scope.creatorOnly'),
		},
		{
			value: "member",
			label:t('transcriptions.scope.memberOnly'),
		},
	];

	const onSelectOption = (index) => {
		setScope(scopes[index].value);
	};

	useEffect( () => {
		if (scope){
			setDropdownOption(scopes.findIndex(el => el.value === scope));
		}		
	}, [ scope ]);

	return (
		<div className='pagination-top__item'>
			<div className='text-nowrap'>{t('transcriptions.scope.select')}</div>
			<CommonDropdown 
				options={
					scopes.map( it => it.label)
				} 
				selectedOption={ dropdownOption }
				setSelectedOption={ onSelectOption }
				className='dropdown-new'
			/>
		</div>
	);
};

TranscriptionsMyScopeSelect.propTypes = {
	scope: PropTypes.string,
	setScope: PropTypes.func,
};

export default TranscriptionsMyScopeSelect;
