import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { reverse } from 'named-urls';
import { Dropdown, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import url from "@/router/urls";

import Button from '@Elements/Button/Button';
import LinkButton from "@Elements/Button/LinkButton";
import Pagination from '@Components/Pagination/Pagination';
import TranscriptionsItemPage from '@Elements/Transcriptions/TranscriptionsItemPage';
import { CheckSecurityGroupPermission } from "@/permissions/SecurityGroups";
import { CheckViewModePermission } from "@/permissions/ViewModes";

import styles from "@Pages/Transcription/TranscriptionDetails/TranscriptionDetails.module.sass";

import TranscriptionDetailsModals from '@Pages/Transcription/TranscriptionDetails/parts/TranscriptionDetailsModals';
// import ModalWrapperChildren from '@Elements/Modal/ModalWrapperChildren';

// import ModalAddPages from '@Elements/Modal/parts/ModalAddPages';
// import ModalAutoTranscribePages from '@Elements/Modal/parts/ModalAutoTranscribePages';
// import ModalTrainOCR from '@Elements/Modal/parts/ModalTrainOCR';
// import ModalDownloadResults from '@Elements/Modal/parts/ModalDownloadResults';
// import ModalImportTextToLayer from '@Elements/Modal/parts/ModalImportTextToLayer';

import ProgressBar from '@Elements/ProgressBar/ProgressBar';

const TranscriptionDetailsPages = ({
	pages,
	percentOfTranscribed,
	lastModificationDate,
	onGoToPage,
	viewMode,
	creationMode,
	transcriptionId,
	transcriptionProcessingStatusDisabledButtons,
	continuation,
	usersCount,
	layers,
}) => {
	const { t } = useTranslation();

	const securityGroup = useSelector((state) => state.auth.credentials.securityGroup);

	const [currentPageNum, setCurrentPageNum] = useState(0);
	const [pagesNum, setPagesNum] = useState(1);
	const [overallTransciptionsNum, setOverallTransciptionsNum] = useState(0);
	const [pagesList, setPagesList] = useState([]);

	//MODALS:
	const [isModalAddPages, setIsModalAddPages] = useState(false);
	const [isModalAutoTranscribePages, setIsModalAutoTranscribePages] = useState(false);
	const [isModalTrainOCR, setIsModalTrainOCR] = useState(false);
	const [isModalDownloadResults, setIsModalDownloadResults] = useState(false);
	const [isModalImportTextToLayer, setIsModalImportTextToLayer] = useState(false);

	const history = useHistory();
	const hasContinuation = continuation && continuation.lastPageId;
	const isEmptyTranscription = pages.hits === 0;

	useEffect(() => {
		setCurrentPageNum(pages.page);
		setPagesNum(pages.pages);
		setOverallTransciptionsNum(pages.hits);
		setPagesList(pages.list);
	}, [pages, pages.list]);

	const onContinueTranscription = () => {
		if (continuation.lastPageId) {
			const linkToTranscriptionView = reverse(url.transcription.viewer, { transcriptionId: parseInt(transcriptionId), pageId: parseInt(continuation.lastPageId) });
			history.push(linkToTranscriptionView);
		}
	};

	const linkToTranscriptionPermissions = reverse(url.transcription.permissions, { transcriptionId: transcriptionId });
	const linkToTranscriptionLayers = reverse(url.transcription.layers, { transcriptionId: transcriptionId });

	return (
		<div className={styles["pages-list__wrapper"]}>
			<TranscriptionDetailsModals
				isModalAddPages={isModalAddPages}
				setIsModalAddPages={setIsModalAddPages}
				isModalAutoTranscribePages={isModalAutoTranscribePages}
				setIsModalAutoTranscribePages={setIsModalAutoTranscribePages}
				isModalTrainOCR={isModalTrainOCR}
				setIsModalTrainOCR={setIsModalTrainOCR}
				isModalDownloadResults={isModalDownloadResults}
				setIsModalDownloadResults={setIsModalDownloadResults}
				isModalImportTextToLayer={isModalImportTextToLayer}
				setIsModalImportTextToLayer={setIsModalImportTextToLayer}
				transcriptionId={transcriptionId}
				layers={layers}
				pageCount={overallTransciptionsNum}
			/>
			<Container>
				<section className={styles["pages-list"]}>
					<div className={styles["pages-list__head"]}>
						<div className={styles["transcription__desktopNav"]}>
							<div
								className='btn-group'
								role='group'
							>
								{CheckViewModePermission("canSeeContinueTranscriptionScreenButton", viewMode) &&
									<Button
										variant='primary'
										onClick={() => onContinueTranscription()}
										disabled={((!hasContinuation) || transcriptionProcessingStatusDisabledButtons)}
									>
										{t('transcription.details.continue')}
									</Button>}
								<LinkButton
									variant='primary'
									href={linkToTranscriptionLayers}
									disabled={false}
								>
									{t('transcription.details.addTextLayer')} ({layers.length})
								</LinkButton>
								<LinkButton
									variant='primary'
									href={reverse(url.transcription.indexes, { transcriptionId: parseInt(transcriptionId) })}
									disabled={isEmptyTranscription}
								>
									{t('transcription.details.indexes')}
								</LinkButton>
								{CheckViewModePermission("canSeeModerationScreenButton", viewMode) &&
									<LinkButton
										variant='primary'
										href={reverse(url.transcription.verification, { transcriptionId: parseInt(transcriptionId) })}
										disabled={!(CheckViewModePermission("canOpenModerationScreen", viewMode) && overallTransciptionsNum > 0)}
									>
										{t('transcription.details.verification')}
									</LinkButton>}
								{CheckViewModePermission("canSeeContributorsScreenButton", viewMode) &&
									<LinkButton
										variant='primary'
										href={linkToTranscriptionPermissions}
										disabled={!CheckViewModePermission("canOpenContributorsScreen", viewMode)}
									>
										{t('transcription.details.contributors')} ({usersCount})
									</LinkButton>}
							</div>
						</div>
						<div className={styles["transcription__mobileNav"]}>
							<Dropdown
								className={styles['navigation__dropdwn']}
							>
								<Dropdown.Toggle
									variant='primary'
									id='details'
									className={styles['navigation__dropdwn-btn']}
								>
									{t('transcription.details.workWithProject')}
								</Dropdown.Toggle>
								<Dropdown.Menu className={styles["transcription__mobileNav--dropdown-menu"]}>
									{CheckViewModePermission("canSeeContinueTranscriptionScreenButton", viewMode) &&
										<Dropdown.Item
											onClick={() => onContinueTranscription()}
											disabled={((!hasContinuation) || transcriptionProcessingStatusDisabledButtons)}
										>
											{t('transcription.details.continue')}
										</Dropdown.Item>}
									<Dropdown.Item
										href={linkToTranscriptionLayers}
										disabled={false}
									>
										{t('transcription.details.addTextLayer')}
									</Dropdown.Item>
									<Dropdown.Item href={reverse(url.transcription.indexes, { transcriptionId: parseInt(transcriptionId) })}
										disabled={isEmptyTranscription}
									>
										{t('transcription.details.indexes')}
									</Dropdown.Item>
									{CheckViewModePermission("canSeeModerationScreenButton", viewMode) &&
										<Dropdown.Item
											href={reverse(url.transcription.verification, { transcriptionId: parseInt(transcriptionId) })}
											disabled={!(CheckViewModePermission("canOpenModerationScreen", viewMode) && overallTransciptionsNum > 0)}
										>
											{t('transcription.details.moderation')}
										</Dropdown.Item>}
									{CheckViewModePermission("canSeeContributorsScreenButton", viewMode) &&
										<Dropdown.Item
											href={linkToTranscriptionPermissions}
											disabled={false}
										>
											{t('transcription.details.contributors')} ({usersCount})
										</Dropdown.Item>}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
					<div className={styles["pages-list__head"]}>
						<div className={styles["transcription__desktopNav"]}>
							<div
								className='btn-group'
								role='group'
							>
								{CheckViewModePermission("canSeeAddNewPagesButton", viewMode) &&
									<Button
										variant='secondary'
										onClick={(e) => {
											e.target.blur();
											setIsModalAddPages(true);
										}}
										disabled={!CheckViewModePermission("canAddNewPages", viewMode) || transcriptionProcessingStatusDisabledButtons || creationMode !== 'web-form'}
									>
										{t('transcription.details.addNewPages')}
									</Button>}
								{CheckViewModePermission("canSeeAutoTranscribePagesButton", viewMode) &&
									<Button
										variant='secondary'
										onClick={() => setIsModalAutoTranscribePages(true)}
										disabled={!(CheckViewModePermission("canAutoTranscribePages", viewMode) && overallTransciptionsNum > 0) || transcriptionProcessingStatusDisabledButtons}
									>
										{t('transcription.details.automaticallyTranscribe')}
									</Button>}

								{CheckSecurityGroupPermission("canTrainOCR", securityGroup) &&
									<Button
										variant='secondary'
										disabled={!(percentOfTranscribed > 0) && !(pages.count > 0)}
										onClick={(e) => {
											e.target.blur();
											setIsModalTrainOCR(true);
										}}
									>
										{t('transcription.details.trainOCR')}
									</Button>}

								<Button
									variant='secondary'
									disabled={!(percentOfTranscribed > 0) && !(pages.count > 0)}
									onClick={(e) => {
										e.target.blur();
										setIsModalDownloadResults(true);
									}}
								>{t('transcription.details.downloadResults')}
								</Button>
								{CheckViewModePermission("canSeeImportTextButton", viewMode) &&
									<Button
										variant='secondary'
										onClick={(e) => {
											e.target.blur();
											setIsModalImportTextToLayer(true);
										}}
										disabled={!(CheckViewModePermission("canImportText", viewMode) && overallTransciptionsNum > 0) || transcriptionProcessingStatusDisabledButtons}
									>
										{t('transcription.details.loadText')}
									</Button>}
							</div>
						</div>
					</div>
					<div className={styles["transcription__mobileNav"]}>
						<Dropdown
							className={styles['navigation__dropdwn']}
						>
							<Dropdown.Toggle
								variant='primary'
								id='details'
								className={styles['navigation__dropdwn-btn']}
							>
								{t('transcription.details.moreOptions')}
							</Dropdown.Toggle>
							<Dropdown.Menu className={styles["transcription__mobileNav--dropdown-menu"]}>
								{(CheckViewModePermission("canSeeAddNewPagesButton", viewMode)) &&
									<Dropdown.Item
										onClick={(e) => {
											e.preventDefault();
											e.target.blur();
											setIsModalAddPages(true);
										}}
										disabled={!CheckViewModePermission("canAddNewPages", viewMode) || transcriptionProcessingStatusDisabledButtons || creationMode !== 'web-form'}
									>
										{t('transcription.details.addNewPages')}
									</Dropdown.Item>}

								{CheckViewModePermission("canSeeAutoTranscribePagesButton", viewMode) &&
									<Dropdown.Item
										onClick={() => setIsModalAutoTranscribePages(true)}
										disabled={!(CheckViewModePermission("canAutoTranscribePages", viewMode) && overallTransciptionsNum > 0) || transcriptionProcessingStatusDisabledButtons}
									>
										{t('transcription.details.automaticallyTranscribe')}
									</Dropdown.Item>}

								{CheckSecurityGroupPermission("canTrainOCR", securityGroup) &&
									<Dropdown.Item
										onClick={() => setIsModalTrainOCR(true)}
										disabled={!(percentOfTranscribed > 0) && !(pages.count > 0)}
									>
										{t('transcription.details.trainOCR')}
									</Dropdown.Item>}

								<Dropdown.Item
									onClick={() => setIsModalDownloadResults(true)}
									disabled={!(percentOfTranscribed > 0) && !(pages.count > 0)}
								>
									{t('transcription.details.downloadResults')}
								</Dropdown.Item>

								{CheckViewModePermission("canSeeImportTextButton", viewMode) &&
									<Dropdown.Item
										onClick={(e) => {
											e.target.blur();
											setIsModalImportTextToLayer(true);
										}}
										disabled={!(CheckViewModePermission("canImportText", viewMode) && overallTransciptionsNum > 0) || transcriptionProcessingStatusDisabledButtons}
									>
										{t('transcription.details.loadText')}
									</Dropdown.Item>}

							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div className={styles["pages-list__head-column"]}>
						<div className={styles["pages-list__header"]}>
							{t('transcription.details.transcriptionFiles')} ({overallTransciptionsNum})
						</div>
						<div className={styles["pages-list__header--thick--item-progress"]}>
							<div>{t('transcriptions.description.transcriptionAdvance')}</div>
							<div className={styles["pages-list__header--thick--item--value"]}>
								<span className={styles["pages-list__header--thick--item--progress-bar"]}><ProgressBar progress={percentOfTranscribed} /></span>
								{percentOfTranscribed}%
							</div>
						</div>

						<div className={styles["pages-list__header--thick"]}>
							<span className={styles["pages-list__header--thick--item"]}>
								{t('transcriptions.description.lastEdited')}&nbsp;{lastModificationDate}
							</span>
						</div>
					</div>

					{(pagesList) && (pagesList.length > 0) && (
						<Pagination
							currentPage={currentPageNum}
							pagesNum={pagesNum}
							onGoToPage={onGoToPage}
						>
							<div className={`${styles["pages-list__grid"]}`}>
								{
									pagesList.map((page) => (
										<TranscriptionsItemPage
											key={page.id}
											page={page}
											viewMode={viewMode}
											creationMode={creationMode}
											isInTranscriptionDetails
											transcriptionId={transcriptionId}
											currentPage={currentPageNum}
											transcriptionProcessingStatusDisabledButtons={transcriptionProcessingStatusDisabledButtons}
											layers={layers}
										/>
									))
								}
							</div>
						</Pagination>
					)}

					{(!pagesList || pagesList.length == 0) &&
						<div className={styles["pages-list__no-page"]}>
							{t('transcription.details.noPagesInfo1')}
							<a href='#' onClick={(e) => {
								e.preventDefault();
								setIsModalAddPages(true);
							}}
							>{t('transcription.details.noPagesInfo2')}
							</a>.
						</div>}
				</section>
			</Container>
		</div>
	);
};

TranscriptionDetailsPages.propTypes = {
	pages: PropTypes.object,
	percentOfTranscribed: PropTypes.number,
	lastModificationDate: PropTypes.string,
	onGoToPage: PropTypes.func,
	viewMode: PropTypes.string,
	creationMode: PropTypes.string,
	transcriptionId: PropTypes.number,
	transcriptionProcessingStatusDisabledButtons: PropTypes.bool,
	continuation: PropTypes.object,
	usersCount: PropTypes.number,
	layers: PropTypes.array,
};

export default TranscriptionDetailsPages;