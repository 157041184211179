import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

import PaginationsItemsPerPage from "@Components/Pagination/PaginationsItemsPerPage";

/* Instrukcja użycia:
Wiemy, że dany blok będzie wymagał modułu paginacji, otaczamy go tym komponentem:
<Pagination>
	<ExampleList />
</Pagination>
w ten sposób przekazany zostaje pierwszy parametr - children
W nadrzędnym komponencie musimy tylko zdefiniować stan dla obecnego numeru strony:
const [currentPage, setCurrentPage] = setState(1);
Api zawssze podaje nam też ilość stron, a zatem to przypisujemy jako trzeci parametr

onGoToPage to referencja do funkcji, która jest odpowiedzialna za zmianę strony
(na przykład użytkownik w module paginacja kliknął na 3 stronę, czyli w komponencie powyżej musimy zrobić przekierowanie na odpowiednią stronę, coś w stylu:
history.push('examplelist/3')
	)
Opcjonalnie metoda ta dostaje nową ilość rekordów na stronę.
*/

const Pagination = ({ children, currentPage, pagesNum, onGoToPage, perPage, title, showPerPage = false, leftTopChildren, className, hideIfOnePage = false }) => {
	const { t } = useTranslation();

	const [ pagesArr, setPagesArr ] = useState([]);
	const [ inputPageNum, setInputPageNum ] = useState(0);
	const [ debouncedPageNum ] = useDebounce(inputPageNum, 500);
	const paginationTop = useRef();
	
	const [ count, setCount ] = useState(perPage);

	const setCountAndReload = (newCount) => {
		setCount(newCount);
		onGoToPage(0, newCount);
	};

	useEffect(() => {
		const db = parseInt(debouncedPageNum);
		if (db !== currentPage && db > 0 && db <= pagesNum) {
			gotoPage(db);
		}
	}, [ debouncedPageNum ]);

	const gotoPage = (pageNum) => {
		window.scrollTo( 
			{
				top: paginationTop.current.offsetParent.offsetTop - 125,
				block: 'start',
			});
		onGoToPage(pageNum);
	};

	const prevButton =  (
		<button
			disabled={currentPage <= 1}
			className='pagination__btn pagination__btn--prev'
			onClick={ () => gotoPage(currentPage - 1) }
		>{t('pagination.previous')}
		</button>
	);
	const nextButton = (
		<button
			disabled={currentPage >= pagesNum}
			className='pagination__btn pagination__btn--next'
			onClick={ () => gotoPage(currentPage + 1) }
		>{t('pagination.next')}
		</button>
	);

	const fPage = (pNum) => (
		<div
			className='pagination-bottom__item'
			onClick={ () => {
				gotoPage(pNum);
			} }
		>{pNum}
		</div>
	);

	useEffect(() => {
		setPagesArr([ ...Array(pagesNum).keys() ].filter((item, index) => {
			let surroundingPageNum = 2;
			if ((currentPage === 1) || (pagesNum >= 5 && currentPage === pagesNum)) {
				surroundingPageNum = 4;
			} else if ((currentPage === 2) || (pagesNum === 4 && currentPage === 4) || (pagesNum > 4 && currentPage === pagesNum - 1)) {
				surroundingPageNum = 3;
			}
			if (index >= (currentPage - surroundingPageNum - 1) && index <= (currentPage + surroundingPageNum - 1)) {
				return item + 1;
			}
			return null;
		}));
		if (currentPage) {
			setInputPageNum(currentPage);
		}
	}, [ currentPage, pagesNum /*, count */ ]);

	if (hideIfOnePage && pagesNum < 2) return <>{children}</>;

	return (
		<>	
			<div className={"pagination-top pagination-top--container " + className}>
				{ paginationTop && 			
				<div
					ref={ paginationTop }
					className='pagination-top__anchor'
				/>}
				{leftTopChildren || showPerPage && 
				<div className='pagination-top__items'>
					{leftTopChildren}
					
					{showPerPage &&
						<PaginationsItemsPerPage
							count={count}
							setCount={setCountAndReload}
							title={title}
						/>}
				</div>}
				
				<div className='pagination-top--left'>
					{prevButton}
					{(pagesNum > 1) ? (
						<div>
							<label htmlFor='inputPageNum' className='d-none'>{inputPageNum}</label>
							<input
								className='pagination__input'
								type='number'
								min='1'
								id='inputPageNum'
								name='inputPageNum'
								value={ inputPageNum }
								onChange={ ev => setInputPageNum(ev.target.value) }
								max={ pagesNum }
							/>
						</div>
					) : currentPage}
					<div>{t('pagination.of')}</div>
					{fPage(pagesNum)}
					{nextButton}
				</div>
				
			</div>
			
			{children}
			
			<div className={"pagination-bottom " + className}>
				{prevButton}
				{(pagesNum > 1) ? (
					<div>
						<label htmlFor='inputPageNum2' className='d-none'>{inputPageNum}</label>
						<input
							className='pagination__input'
							type='number'
							min='1'
							id='inputPageNum2'
							name='inputPageNum2'
							value={ inputPageNum }
							onChange={ ev => setInputPageNum(ev.target.value) }
							max={ pagesNum }
						/>
					</div>
				) : currentPage}
				<div>{t('pagination.of')}</div>
				{fPage(pagesNum)}
				{nextButton}
			</div>
				
		</>
	);
};

Pagination.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
	currentPage: PropTypes.number,
	pagesNum: PropTypes.number,
	onGoToPage: PropTypes.func,
	perPage: PropTypes.number,
	title: PropTypes.string,
	showPerPage: PropTypes.bool,
	leftTopChildren: PropTypes.element,
	className: PropTypes.string,
	hideIfOnePage: PropTypes.bool,
};

export default Pagination;
