import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";

import styles from "@Pages/Transcription/TranscriptionDetails/TranscriptionDetails.module.sass";

import ModalWrapperChildren from '@Elements/Modal/ModalWrapperChildren';

import ModalAddPages from '@Elements/Modal/parts/ModalAddPages';
import ModalAutoTranscribePages from '@Elements/Modal/parts/ModalAutoTranscribePages';
import ModalTrainOCR from '@Elements/Modal/parts/ModalTrainOCR';
import ModalDownloadResults from '@Elements/Modal/parts/ModalDownloadResults';
import ModalImportTextToLayer from '@Elements/Modal/parts/ModalImportTextToLayer';

const TranscriptionDetailsModals = ({
	isModalAddPages,
	setIsModalAddPages,

	isModalAutoTranscribePages,
	setIsModalAutoTranscribePages,

	isModalTrainOCR, 
	setIsModalTrainOCR,

	isModalDownloadResults, 
	setIsModalDownloadResults,

	isModalImportTextToLayer,
	setIsModalImportTextToLayer,

	transcriptionId,
	layers,
	pageCount,
}) => {
	const { t } = useTranslation();

	return (
		<div className={styles["pages-list__wrapper"]}>
			<ModalWrapperChildren
				modalVisible={isModalAddPages}
				setModalVisible={setIsModalAddPages}
				title={t('modals.addPages.title')}
			>
				{(modalProps) => (
					<ModalAddPages
						modalProps={modalProps}
						transcriptionId={transcriptionId}
					/>
				)}
			</ModalWrapperChildren>

			<ModalWrapperChildren
				modalVisible={isModalAutoTranscribePages}
				setModalVisible={setIsModalAutoTranscribePages}
				title={t('modals.autoTranscribePage.title')}
			>
				{(modalProps) => (
					<ModalAutoTranscribePages
						modalProps={modalProps}
						transcriptionId={transcriptionId}
						layers={layers}
					/>
				)}
			</ModalWrapperChildren>

			<ModalWrapperChildren
				modalVisible={isModalTrainOCR}
				setModalVisible={setIsModalTrainOCR}
				title={t('modals.trainOCR.title')}
			>
				{(modalProps) => (
					<ModalTrainOCR
						modalProps={modalProps}
						transcriptionId={transcriptionId}
						layers={layers}
						pageCount={pageCount}
					/>
				)}
			</ModalWrapperChildren>

			<ModalWrapperChildren
				modalVisible={isModalDownloadResults}
				setModalVisible={setIsModalDownloadResults}
				title={t('modals.downloadResults.title')}
				withClose
			>
				{(modalProps) => (
					<ModalDownloadResults
						modalProps={modalProps}
						transcriptionId={transcriptionId}
						layers={layers}
					/>
				)}
			</ModalWrapperChildren>

			<ModalWrapperChildren
				modalVisible={isModalImportTextToLayer}
				setModalVisible={setIsModalImportTextToLayer}
				title={t('modals.importTextToLayer.title')}
				withClose
			>
				{(modalProps) => (
					<ModalImportTextToLayer
						modalProps={modalProps}
						transcriptionId={transcriptionId}
						layers={layers}
					/>
				)}
			</ModalWrapperChildren>
		</div>
	);
};

TranscriptionDetailsModals.propTypes = {
	isModalAddPages: PropTypes.bool,
	setIsModalAddPages: PropTypes.func,

	isModalAutoTranscribePages: PropTypes.bool,
	setIsModalAutoTranscribePages: PropTypes.func,

	isModalTrainOCR: PropTypes.bool, 
	setIsModalTrainOCR: PropTypes.func,

	isModalDownloadResults: PropTypes.bool, 
	setIsModalDownloadResults: PropTypes.func,

	isModalImportTextToLayer: PropTypes.bool,
	setIsModalImportTextToLayer: PropTypes.func,

	transcriptionId: PropTypes.number,
	layers: PropTypes.array,
	pageCount: PropTypes.number,
};

export default TranscriptionDetailsModals;