import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import PropTypes from "prop-types";

import { CheckViewModePermission } from "@/permissions/ViewModes";

import api from "@/services/api";
import Button from "@Elements/Button/Button";

import ModalBaseCancelConfirm from '@Elements/Modal/ModalBaseCancelConfirm';

import styles from "@Pages/Transcription/TranscriptionPermissions/TranscriptionPermissions.module.sass";

const TranscriptionPermissionsGroups = ({ groups, getTranscriptionPermissionsData, viewMode }) => {

	const { t } = useTranslation();
	const { transcriptionId } = useParams();
	const wrapperRef = useRef(null);

	const [display, setDisplay] = useState(false);
	const [allowedGroups, setAllowedGropus] = useState([]);
	const [namesOfGroups, setNamesOfGroups] = useState([]);
	const [groupToAdd, setGroupToAdd] = useState({});
	const [inputValue, setInputValue] = useState('');

	const [deleteGroupId, setDeleteGroupId] = useState(null);

	const [foundGroups, setFoundGroups] = useState([]);

	useEffect(() => {
		window.addEventListener("mousedown", handleClickOutside);
		return () => {
			window.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const onAddGroupSubmit = () => {
		api.post(`/transcriptions/${transcriptionId}/allowed-groups`, { groupId: groupToAdd.id })
			.then(() => {
				getTranscriptionPermissionsData();
			});
		setInputValue('');
		setNamesOfGroups(namesGroups => [...namesGroups, groupToAdd.name]);
	};

	const onWrite = async (path) => {
		setInputValue(path);
		try {
			if (path.length > 0) {
				const { data } = await api.get(`/groups/my-groups/${path}`);
				if (data.length > 0) {
					const findGroups = data.filter(el => {
						const is = groups.some(group => group.name.toLowerCase() === el.name.toLowerCase());
						return !is;
					});
					setFoundGroups(findGroups);
					setDisplay(findGroups.length > 0);
				} else {
					setFoundGroups([]);
					setDisplay(false);
				}
			} else {
				setFoundGroups([]);
				setDisplay(false);
			}
		} catch (error) {
			// eslint-disable-next-line
			console.log(error);
		}
	};

	const updateInput = (value) => {
		setDisplay(false);
		setGroupToAdd(value);
		setInputValue(value.name);
	};

	const handleClickOutside = event => {
		const { current: wrap } = wrapperRef;
		if (wrap && !wrap.contains(event.target)) {
			setDisplay(false);
		}
	};

	const onDeleteGroupConfirmed = async () => {
		try {
			await api.delete(`/transcriptions/${transcriptionId}/allowed-groups/${deleteGroupId}`);
			getTranscriptionPermissionsData();
		} catch (error) {
			// eslint-disable-next-line
			console.log(error);
		} finally {
			setDeleteGroupId(null);
		}
	};

	return (
		<>
			{/* DELETE USER MODAL */}
			<ModalBaseCancelConfirm
				modalVisible={!!deleteGroupId}
				setModalVisible={() => setDeleteGroupId(null)}
				title={t('modals.permissionsDeleteGroup.title')}
				bodyHTML={t('modals.permissionsDeleteGroup.bodyHTML')}
				cancelButtonName={t('form.cancel')}
				confirmButtonName={t('modals.permissionsDeleteGroup.button')}
				onConfirm={onDeleteGroupConfirmed}
			/>
			<div className={styles["permissions__group--container"]}>
				<div className={styles["permissions__group"]}>
					{groups && (
						<>
							<h3 className={styles["permissions__additional-text"]} style={{ marginBottom: '20px' }}>{t('transcription.permissions.groups')}</h3>
							{groups.map((group, index) => (
								<div key={group.id} className={styles['permissions__group--item']}>
									{index + 1}. {group.name}
									<span> ({t('auth.account.groupCreator')}: {group.creator.email})</span>
									{CheckViewModePermission("canRemoveGroups", viewMode) &&
										<Button
											variant='text'
											onClick={() => {
												setDeleteGroupId(group.id);
											}}
										>
											{t('transcription.permissions.deleteUser')}
										</Button>}
								</div>
							))}
						</>
					)}
				</div>
				{CheckViewModePermission("canAddGroups", viewMode) &&
					<>
						<h3 className={styles["permissions__additional-text"]}>{t('transcription.permissions.addGroupTitle')}</h3>
						<p className='text--small'>{t('transcription.permissions.addGroupName')} <span style={{ color: "#005ca9" }}>*</span></p>
						<div className={styles["permissions__add--container"]}>
							<form
								className={styles["permissions__add--form"]}
							>
								<div
									className={styles["permissions__add--wrapper"]}
									ref={wrapperRef}
								>
									<input
										type='text'
										name='groupName'
										placeholder={t('transcription.permissions.groupPlaceholder')}
										className={`form-control permissions__add--form-input ${styles[display ? "permissions__add--form-input--expanded" : "permissions__add--form-input"]}`}
										onChange={(e) => onWrite(e.target.value)}
										onClick={(e) => onWrite(e.target.value)}
										value={inputValue}
										autoComplete='off'
									/>

									{display && foundGroups.length > 0 && (
										<div className={styles["permissions__dropdown-autocomplete"]}>
											{foundGroups.map(group => (
												<div
													onClick={() => updateInput(group)}
													key={group.id}
													className={styles["permissions__dropdown-autocomplete--item"]}
												>
													{group.name} / {group.creator.email}
												</div>
											))}
										</div>
									)}
								</div>
								<div className={styles['permissions__input-info']}>
									<i className='icon-info' />{t('transcription.permissions.addGroupInfo')}
								</div>
								<div className={styles["permissions__submit--wrapper"]}>
									<Button
										variant='submit'
										className={styles["permissions__submit--bt"]}
										label={t('group.addButton')}
										disabled={inputValue !== groupToAdd.name}
										onClick={() => onAddGroupSubmit()}
									>
										{t('group.addButton')}
									</Button>
								</div>
							</form>
							<div style={{ color: "#606060", fontSize: "0.8rem" }}><span style={{ color: "#005ca9" }}>*</span> {t('transcription.permissions.requiredFields')}</div>
						</div>
					</>}
			</div>
		</>
	);
};

TranscriptionPermissionsGroups.propTypes = {
	groups: PropTypes.array,
	getTranscriptionPermissionsData: PropTypes.func,
	viewMode: PropTypes.string,
};

export default TranscriptionPermissionsGroups;
