import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Form from "react-bootstrap/Form";

import styles from '@Elements/Form/Form.module.sass';
import Button from "@Elements/Button/Button";

const FormInputCustom = ({
	name,
	label,
	type,
	placeholder,
	register,
	validateData,
	errorMessage,
	correctMessage,
	infoMessage,
	inputContainerClassName,
	onDeleteFunction,
	onBlur,
	isDisabled,
	isRequired,
}) => {
	const { t } = useTranslation();
	const [hidePassword, setPasswordVisibility] = useState(true);

	const icon = (type === 'password' ?
		<i
			className={`${(hidePassword) ? 'icon-eye-disabled' : 'icon-eye'} ${styles["form__input-password"]}`}
			onClick={() => setPasswordVisibility(visible=>!visible)}
		/> : null);

	return (
		<div
			onBlur={onBlur}
			className={`
			${styles["form__input-container"]}
			${inputContainerClassName ? inputContainerClassName : null}
			` }
		>
			{(onDeleteFunction) ?
				(
					<div className={styles["form__input--delete-container"]}>
						<Form.Label>{isRequired && <span className='asterisk'>*</span>}{label}</Form.Label>
						{(!isDisabled) && (
							<Button
								variant='text'
								onClick={onDeleteFunction}
								className={styles["form__input--delete"]}
							>
								{t('form.delete')}
							</Button>
						)}
					</div>
				)
				:
				(<Form.Label>{isRequired && <span className='asterisk'>*</span>}{label}</Form.Label>)}
			<Form.Row className={styles["form__input-row"]}>
				<input
					className={`form-control 
						${errorMessage ? styles["form__input--incorrect"] : null}
						${correctMessage ? styles["form__input--correct"] : null}
					` }
					type={(type === 'password') ? (hidePassword ? 'password' : 'text') : (type === "RichTextHidden" ? 'hidden' : type)}
					name={name}
					id={name}
					autoComplete='off'
					placeholder={placeholder}
					disabled={isDisabled}
					{...register(name, validateData)}
				/>
				{icon}
				{errorMessage ? (
					<div className={styles["form__message--invalid"]}>
						<i className='icon-close' />{errorMessage}
					</div>)
					: null}
				{infoMessage ? (
					<div className={styles["form__message--info"]}>
						<i className='icon-info' style={{ marginRight: '6px' }} />{infoMessage}
					</div>)
					: null}
			</Form.Row>
		</div>
	);
};
FormInputCustom.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	register: PropTypes.func,
	validateData: PropTypes.object,
	errorMessage: PropTypes.string,
	correctMessage: PropTypes.string,
	infoMessage: PropTypes.string,
	inputContainerClassName: PropTypes.string,
	onDeleteFunction: PropTypes.func,
	onBlur: PropTypes.func,
	isDisabled: PropTypes.bool,
	isRequired: PropTypes.bool,
};

export default FormInputCustom;
