import React from 'react';
import PropTypes from 'prop-types';
import styles from "@Components/Divider/Divider.module.sass";

const Divider = props => {
	return (
		<div className={ styles["divider"] } />
	);
};

Divider.propTypes = {};

export default Divider;