import pl from '@/locales/pl/default.json';
import en from '@/locales/en/default.json';

export const langs = [
	{
		id: 'pl',
		name: 'Polski',
	},
	{
		id: 'en',
		name: 'English',
	},
];

export const dct = { ...{}, pl, en };