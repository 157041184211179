import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";
import { useForm } from 'react-hook-form';

import FormInputCustom from "@Elements/Form/FormInputCustom";
import FormSubmit from "@Elements/Form/FormSubmit";
import Button from '@Elements/Button/Button';

import styles from "@Elements/Modal/parts/LayersActions/ModalEditLayer.module.sass";

const ModalEditLayer = ({ handleOnClose, layer, onEditComplete }) => {
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: {
			errors,
			isValid,
		},
	} = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			name: layer ? layer.name : "",
			description: layer ? layer.description : "",
		},
	});

	const onSubmit = (data) => {
		onEditComplete({ ...data, id: layer.id });
	};

	return (
		<form
			className={styles["form"]}
			onSubmit={handleSubmit(onSubmit)}
		>
			<Modal.Body>
				<FormInputCustom
					register={register}
					label={t('transcriptions.layers.layerName')}
					name='name'
					validateData={{
						required: {
							value: true,
							message: t('form.messages.fillField'),
						},
					}}
					errorMessage={errors.name ? errors.name.message : ''}
					isRequired
				/>

				<FormInputCustom
					register={register}
					label={t('transcriptions.addLayerPage.layerDescription')}
					name='description'
					inputContainerClassName={styles['form--block']}
					validateData={{
						required: {
							value: true,
							message: t('form.messages.fillField'),
						},
					}}
					errorMessage={errors.description ? errors.description.message : ''}
					infoMessage={t('transcriptions.addLayerPage.descriptionInfo')}
					isRequired
				/>
				
				<p className={styles["form--info"]}><span className='asterisk'>*</span>{t('transcriptions.layers.info')}</p>

			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={handleOnClose}
					className='modal__button--cancel'
				>
					{t('form.cancel')}
				</Button>
				<FormSubmit
					label={t('form.save')}
					className='block__button--submit'
					variant='primary'
					disabled={!isValid}
				/>
			</Modal.Footer>
		</form>
	);
};

ModalEditLayer.propTypes = {
	handleOnClose: PropTypes.func,
	layer: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		description: PropTypes.string,
	}),
	onEditComplete: PropTypes.func,
};

export default ModalEditLayer;