import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from "@Pages/Event/Event.module.sass";
import TranscriptionsItem from '@Elements/Transcriptions/TranscriptionsItem';
import url from "@/router/urls";
import LinkButton from "@Elements/Button/LinkButton";
import { useTranslation } from 'react-i18next';
import { reverse } from 'named-urls';
import Blank from  '@Assets/images/empty.jpg';
import draftToHtml from 'draftjs-to-html';

const Details = ({thumbnailUrl, description, openingInfo, transcriptions, eventId, isAdmin}) => {
	const [descriptionMarkup, setDescriptionMarkup] = useState('');
	const [openingMarkup, setOpeningMarkup] = useState('');

	const { t } = useTranslation();

	const isValidJSON = (data) => {
		try {
			JSON.parse(data);
			return true;
		} catch (err) {
			return false;
		}
	};

	const desc = (obj, state) => {
		if(obj) {
			const markup = isValidJSON(obj) ? draftToHtml(JSON.parse(obj)) : obj;
			state(markup);
		}
	};
	
	useEffect(() => {
		desc(description, setDescriptionMarkup);
		desc(openingInfo, setOpeningMarkup);
	}, [description, openingInfo]);
	
	return (
		<>
			<div className={ styles[`details`] }>
				<div className={ styles[`details__img`] } style={{backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : `url(${Blank})`}}/>
				<div>
					<div className={ styles[`details__description`] }>
						<p className={ styles[`details__text--title`] }>{t('events.details.description')}</p>
						<p className={ styles[`details__text--body`]} dangerouslySetInnerHTML={{ __html: descriptionMarkup ? descriptionMarkup : ''}} />
					</div>
					<p className={ styles[`details__text--title`] }>{t('events.details.openingInfo')}</p>
					<p className={ styles[`details__text--body`] } dangerouslySetInnerHTML={{ __html: openingMarkup ? openingMarkup : ''}} />
				</div>
			</div>
			<div className={ styles[`details__list-header`] }>
				<span className={ styles[`details__list-title`] }>{t('events.details.toTranscribe')} ({transcriptions?.length})</span> 
				{isAdmin && 
					<LinkButton variant='primary' className={ styles["event__button--attend"] } href={reverse(`${url.eventTranscriptions}`, { id: eventId })} >
						{t('events.details.chooseProjects')}
					</LinkButton>}
			</div>
			{(transcriptions?.length > 0) &&
				transcriptions.map((item, index) => {
					return (
						<TranscriptionsItem
							isSearchView
							transcription={ item }
							key={ item.analyticInfo.title + index.toString() }
							index={index + 1}
							isEventView
						/>
					);
				})}
		</>
	);
};

Details.propTypes = {
	thumbnailUrl: PropTypes.string,
	description: PropTypes.string,
	openingInfo: PropTypes.string,
	transcriptions: PropTypes.array,
	eventId: PropTypes.string,
	isAdmin: PropTypes.bool,
};

export default Details;