import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import FileSaver from "file-saver";

import api from "@/services/api";
import { alertActions } from "@/store/actions/alert.actions";
import Alert from "@Elements/Alert/Alert";
import DropdownForm from "@Elements/Dropdown/DropdownForm";
import FormSubmit from "@Elements/Form/FormSubmit";

import Button from "@Elements/Button/Button";
import modalStyles from '@Elements/Modal/parts/ModalDropdown.module.sass';

const ModalDownloadResults = ({ modalProps, transcriptionId, layers }) => {
	const { t } = useTranslation();
	
	const AVAILABLE_FILE_FORMATS = [
		{ format: 'pdf', label: t('modals.downloadResults.options.pdf') },
		{ format: 'mobi', label: t('modals.downloadResults.options.mobi') },
		{ format: 'epub', label: t('modals.downloadResults.options.epub') },
		{ format: 'hocr', label: t('modals.downloadResults.btOptions.hocr'), oneFilePerPage: false },
		{ format: 'alto', label: t('modals.downloadResults.btOptions.alto'), oneFilePerPage: false },
		{ format: 'plain-text', label: t('modals.downloadResults.btOptions.text'), oneFilePerPage: false },
		{ format: 'tei', label: t('modals.downloadResults.btOptions.tei'), oneFilePerPage: false },
		{ format: 'hocr', label: t('modals.downloadResults.btOptions.hocrZipPages'), oneFilePerPage: true },
		{ format: 'alto', label: t('modals.downloadResults.btOptions.altoZipPages'), oneFilePerPage: true },
		{ format: 'plain-text', label: t('modals.downloadResults.btOptions.textZipPages'), oneFilePerPage: true },
		{ format: 'tei', label: t('modals.downloadResults.btOptions.teiZipPages'), oneFilePerPage: true },
	];
	
	const AVAILABLE_FILE_FORMATS_ALL_LAYERS = [
		{ format: 'hocr', label: t('modals.downloadResults.btOptions.hocrZipLayers') },
		{ format: 'alto', label: t('modals.downloadResults.btOptions.altoZipLayers') },
		{ format: 'plain-text', label: t('modals.downloadResults.btOptions.textZipLayers') },
		{ format: 'tei', label: t('modals.downloadResults.btOptions.teiZipLayers') },
	];
	
	const layersOptions = [
		{ value: '___all___', label: t('modals.downloadResults.allLayers') },
		...layers.map(item => ({
			value: item.id,
			label: `${t('modals.downloadResults.textLayerLabel')}: ${item.name}`,
		})),
	];

	const dispatch = useDispatch();

	const { control, handleSubmit, watch } = useForm({
		defaultValues: {
			selectedLayer: layersOptions[1],
			fileFormat: AVAILABLE_FILE_FORMATS[0],
			fileFormatAllLayers: AVAILABLE_FILE_FORMATS_ALL_LAYERS[0],
		},
		mode: "onChange",
	});

	const watchSelectedLayer = watch('selectedLayer');
	const allLayersSelected = useMemo(() => watchSelectedLayer.value === '___all___', [watchSelectedLayer]);

	const [retryRequestURL, setRetryRequestURL] = useState(null);
	const [waitingForResponse, setWaitingForResponse] = useState(false);

	const handleOnClose = () => modalProps.setModalVisible(false);

	const requestEbookIsReady = useCallback(() => {
		if (retryRequestURL) {
			api.get(retryRequestURL).then(res => {
				switch (res.data.status) {
					case "READY": {
						setRetryRequestURL(null);
						if (res.data.details) {
							window.open(res.data.details, "_blank");
							handleOnClose();
						}
						break;
					}

					case "FAILED": {
						setRetryRequestURL(null);

						dispatch(
							alertActions.setAlert({
								type: "danger",
								icon: "icon-circle-warning-empty",
								text: "form.messages.downloadFailed",
								close: true,
							}),
						);

						window.scrollTo(0, 0);

						break;
					}

					default:
						if (retryRequestURL) {
							setTimeout(() => requestEbookIsReady(), 3000);
						}
				}
			});
		}
	});

	useEffect(() => {
		if (retryRequestURL) {
			requestEbookIsReady();
		}
	}, [retryRequestURL]);

	useEffect(() => {
		return () => {
			setRetryRequestURL(null);
		};
	}, []);

	const downloadEbook = (method, selectedLayerId) => {
		setWaitingForResponse(true);
		const url = `/transcriptions/${transcriptionId}/eBooks/${selectedLayerId}?format=${method.toUpperCase()}&titlepage=false`;
		api.post(url).then(res => {
			res && setRetryRequestURL(res.headers.location.replace("/api", ""));
			setWaitingForResponse(false);
		}).catch(() => {
			dispatch(
				alertActions.setAlert({
					type: "danger",
					icon: "icon-circle-warning-empty",
					text: "form.messages.downloadFailed",
					close: true,
				}),
			);
			setWaitingForResponse(false);
		});
	};

	const downloadDocument = (method, selectedLayerId, oneFilePerPage) => {
		setWaitingForResponse(true);
		let url = `/transcriptions/${transcriptionId}/export/content?type=${method}`;
		if (selectedLayerId)
			url += "&layerId=" + selectedLayerId;
		if (oneFilePerPage !== undefined)
			url += "&oneFilePerPage=" + oneFilePerPage;
		api.get(url, {
			responseType: "blob",
		}).then(response => {
			let fileName = response.headers["content-disposition"].split("filename=")[1];
			FileSaver.saveAs(response.data, fileName);
			setWaitingForResponse(false);
			handleOnClose();
		});
	};

	const onSubmit = data => {
		if (data.selectedLayer.value === '___all___') {
			downloadDocument(data.fileFormatAllLayers.format);
		} else if (['hocr', 'alto', 'tei', 'plain-text'].includes(data.fileFormat.format) ) {
			downloadDocument(data.fileFormat.format, data.selectedLayer.value, data.fileFormat.oneFilePerPage);
		} else {
			downloadEbook(data.fileFormat.format, data.selectedLayer.value);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} >
			<Modal.Body>
				<Alert />
				{!!retryRequestURL || waitingForResponse ? (
					<div className='modal-spinner'>
						<div className='modal-spinner__loading' />
					</div>
				) : (
					<div>
						{layers.length > 1 && (
							<div>
								<DropdownForm
									options={layersOptions}
									control={control}
									name='selectedLayer'
									className={modalStyles['modal-dropdown']}
								/>
							</div>
						)}

						{!allLayersSelected && (
							<div style={{ maxWidth: '250px', marginTop: '2rem' }}>
								<DropdownForm
									options={AVAILABLE_FILE_FORMATS}
									control={control}
									name='fileFormat'
									className={modalStyles['modal-dropdown']}
								/>
							</div>)}

						{allLayersSelected && (
							<div style={{ maxWidth: '250px', marginTop: '2rem' }}>
								<DropdownForm
									options={AVAILABLE_FILE_FORMATS_ALL_LAYERS}
									control={control}
									name='fileFormatAllLayers'
									className={modalStyles['modal-dropdown']}
								/>
							</div>)}

						<div className={modalStyles["form-buttons"]}>
							<Button
								variant='secondary'
								onClick={() => handleOnClose()}
								className='block__button--cancel'
							>
								{t('form.cancel')}
							</Button>
							<FormSubmit
								label={t('modals.importTextToLayer.importText')}
								className='block__button--submit'
								variant='submit'
							/>
						</div>
					</div>
				)}

			</Modal.Body>

		</form>
	);
};

ModalDownloadResults.propTypes = {
	modalProps: PropTypes.object,
	transcriptionId: PropTypes.number,
	layers: PropTypes.array,
};

export default ModalDownloadResults;
