const ViewModesPermissions = {
	"canSeeInstruction": ["transcribe", "verify", "admin", "student"],
	"canSeeContinueTranscriptionScreenButton": ["transcribe", "verify", "admin", "student"],
	"canSeeModerationScreenButton": ["transcribe", "verify", "admin", "student"],
	"canOpenModerationScreen": ["verify", "admin"],
	"canSeeContributorsScreenButton": ["transcribe", "verify", "admin", "student"],
	"canOpenContributorsScreen": ["transcribe", "verify", "admin"],
	"canSeeAddNewPagesButton": ["transcribe", "verify", "admin", "student"],
	"canAddNewPages": ["admin"],
	"canRemovePages": ["admin"],
	"canSeeAutoTranscribePagesButton": ["transcribe", "verify", "admin", "student"],
	"canAutoTranscribePages": ["transcribe", "verify", "admin"],
	"canSeeImportTextButton": ["transcribe", "verify", "admin", "student"],
	"canImportText": ["transcribe", "verify", "admin"],
	"canAddUser": ["admin"],
	"canRemoveUser": ["admin"],
	"canAddGroups": ["admin"],
	"canRemoveGroups": ["admin"],
	"canChangeUserRole": ["admin"],
	"canSeeComments": ["transcribe", "verify", "admin"],
	"canAddComments": ["admin", "verify"],
	"canRemoveAnyComments": ["admin"],
	"canOperateOnIndexes": ["transcribe", "verify", "admin"],
	"canRemoveTranscription": ["admin"],
	"canEditTranscription": ["admin"],
	"canPublishTranscription": ["admin"],
	"canUnpublishTranscription": ["admin"],
	"canCheckVerses": ["transcribe", "verify", "admin"],
	"canCheckAllVerses": ["verify", "admin"],
	"canAcceptAndRejectVerses": ["verify", "admin"],
	"canEditVerses": ["transcribe", "verify", "admin"],
	"canSeeVersesStatus": ["transcribe", "verify", "admin"],
	"canSeeEditorMenu": ["transcribe", "verify", "admin"],
	"canOperateOnTranscriptionLayers": ["admin"],
	"hasAdvancedLayerViewing": ["transcribe", "verify", "admin"],
};

export const CheckViewModePermission = (permission, viewMode) => {
	return ViewModesPermissions[permission].includes(viewMode);
};
