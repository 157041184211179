import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import ModalSendToDigiLab from '@Elements/Modal/parts/ModalSendToDigiLab';
import ModalRedownloadExternal from '@Elements/Modal/parts/ModalRedownloadExternal';

import styles from "@Pages/Transcription/TranscriptionDetails/TranscriptionDetails.module.sass";

import dLibraLogo from '@Assets/images/dLibra.svg';

const TranscriptionDetailsDigiLabStatus = ({ transcriptionId, viewMode, status, externalId, pages, isPublic }) => {
	const { t } = useTranslation();

	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);

	const [isModalSendToDigiLab, setIsModalSendToDigiLab] = useState(false);
	const [isModalRedownloadExternal, setIsModalRedownloadExternal] = useState(false);

	return (
		<div>
			<ModalWrapperChildren
				modalVisible={ isModalSendToDigiLab }
				setModalVisible={ setIsModalSendToDigiLab }
				title={ t('modals.sendToDigiLab.title') }
			>
				<ModalSendToDigiLab
					transcriptionId={ parseInt(transcriptionId) }
					handleOnClose={ () => setIsModalSendToDigiLab(false) }
				/>
			</ModalWrapperChildren>
			
			<ModalWrapperChildren
				modalVisible={ isModalRedownloadExternal }
				setModalVisible={ setIsModalRedownloadExternal }
				title={ t('modals.redownloadExternal.title') }
			>
				<ModalRedownloadExternal
					transcriptionId={ parseInt(transcriptionId) }
					handleOnClose={ () => setIsModalRedownloadExternal(false) }
				/>
			</ModalWrapperChildren>
			
			{(isLoggedIn) && status && status !== "undefined" &&
				<div>
					<p style={{ marginBottom: "10px" }}>
						<img src={dLibraLogo} />
						<a style={{ marginLeft: "15px" }}
							className={styles["transcription__meta--links"]}
							href={externalId}
							target='_blank'
							rel='noopener noreferrer'
						>
							{t("transcription.details.dLibra.importedFromDLibra")}
						</a>
						{viewMode === "admin" &&
							<a style={{ marginLeft: "15px" }}
								href='#'
								onClick={ () => setIsModalRedownloadExternal(true) }
							>
								{t("modals.redownloadExternal.title")}
							</a>}
					</p>
					<p> {t("transcription.details.dLibra.status")}:
						<span style={{ marginLeft: "10px", marginRight: "10px" }}>
							{
								status === "new" ? t("transcription.details.dLibra.projentNotSent") :
									status === "ready" ? t("transcription.details.dLibra.projectBeingVerified") :
										status === "rejected" ? <span style={{ color: "#AC4848" }}>{t("transcription.details.dLibra.projectRejected")}</span> :
											status === "accepted" ? <span style={{ color: "#0A585D" }}><i className='icon-accept' />{t("transcription.details.dLibra.projectAccepted")}</span> :
												status === "sent" ? <span style={{ color: "#0A585D" }}><i className='icon-accept' />{t("transcription.details.dLibra.projectAccepted")}</span> :
													status === "error" ? <span style={{ color: "#AC4848" }}>{t("transcription.details.dLibra.projectError")}</span> : ""
							}
						</span>
						{viewMode === "admin" && pages?.count > 0 &&
							<>{ status === "new" ? (
								<a
									href='#'
									onClick={ () => setIsModalSendToDigiLab(true) }
								>
									{t("transcription.details.dLibra.sendProject")}
								</a>) :
								status === "rejected" ? (
									<>
										<i
											className='icon-question-reversed'
											data-tooltip-id='custom-tooltip' data-tooltip-content={t("transcription.details.dLibra.sendProjectRejectedTooltip")}
											data-place='right'
										>
											<span className='d-none'>{t("transcription.details.dLibra.sendProjectRejectedTooltip")}</span>
										</i>
										<a href='#' style={{ marginLeft: "10px" }} onClick={ () => setIsModalSendToDigiLab(true) }>{t("transcription.details.dLibra.sendProjectAgain")}</a>
									</>) :
									status === "error" ? (
										<>
											<a href='#' style={{ marginLeft: "10px" }} onClick={ () => setIsModalSendToDigiLab(true) }>{t("transcription.details.dLibra.sendProjectAgain")}</a>
										</>) : "" }
							</>}
					</p>
				</div>}
		</div>
	);
};

TranscriptionDetailsDigiLabStatus.propTypes = {
	transcriptionId: PropTypes.number,
	viewMode: PropTypes.string,
	status: PropTypes.string,
	externalId: PropTypes.string,
	pages: PropTypes.object,
	isPublic: PropTypes.bool,
};

export default TranscriptionDetailsDigiLabStatus;
