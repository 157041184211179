import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

import url from "@/router/urls";
import Button from '@Elements/Button/Button';

import styles from "@Pages/Transcription/TranscriptionDetails/TranscriptionDetails.module.sass";

const TranscriptionDetailsMetadata = ({ metadata, viewMode }) => {

	const { t } = useTranslation();

	const currentLang = useSelector((state) => state.app.currentLang);

	const [collapsed, setCollapsed] = useState(true);
	const [TranscriptionData, setTranscriptionDetails] = useState({});
	const [analyticLanguage, setAnalyticLanguage] = useState();
	const [monographicLanguage, setMonographicLanguage] = useState();
	const [Type, setType] = useState();
	const [signature, setSignature] = useState();
	const [Chapter, setChapter] = useState();
	const [Volume, setVolume] = useState();
	const [PageRange, setPageRange] = useState();
	const [Publisher, setPublisher] = useState();
	const [PublicationPlace, setPublicationPlace] = useState();
	const [PublicationDate, setPublicationDate] = useState();
	const [ISBN, setISBN] = useState();
	const [EISBN, setEISBN] = useState();
	const [EISSN, setEISSN] = useState();
	const [ISSN, setISSN] = useState();
	const [Pages, setPages] = useState();
	const [IssueNo, setIssueNo] = useState();
	const [Summary, setSummary] = useState();
	const [Keywords, setKeywords] = useState([]);
	const [links, setLinks] = useState([]);
	const [MonographicActors, setMonographicActors] = useState([]);
	const [AnalyticActors, setAnalyticActors] = useState([]);
	const [externalUrl, setExternalUrl] = useState('');
	const [externalId, setExternalId] = useState('');
	const [monographicTitle, setMonographicTitle] = useState('');
	const [analyticTitle, setAnalyticTitle] = useState('');

	const toggle = () => {
		setCollapsed(!collapsed);
	};

	const roleFilter = (array, role) => {
		return array.filter(arr => arr.role.code === role);
	};
	const findCodes = (array) => {
		return array.map(a => a.role.code);
	};

	useEffect(() => {
		if (metadata) {
			setTranscriptionDetails(metadata);
			if (metadata.analyticInfo.language) {
				setAnalyticLanguage(metadata.analyticInfo.language[currentLang]);
			}
			if (metadata.monographicInfo.language) {
				setMonographicLanguage(metadata.monographicInfo.language[currentLang]);
			}
			setAnalyticTitle(metadata.analyticInfo.title);
			setAnalyticActors(metadata.analyticInfo.actors);

			if (metadata.monographicInfo.biblType) {
				setType(metadata.monographicInfo.biblType[currentLang]);
			}
			setSignature(metadata.monographicInfo.signature);
			setMonographicTitle(metadata.monographicInfo.title);
			setChapter(metadata.monographicInfo.chapterNo);
			setVolume(metadata.monographicInfo.volumeNo);
			setPageRange(metadata.monographicInfo.pageRange);
			setPublisher(metadata.monographicInfo.publisher);
			setPublicationPlace(metadata.monographicInfo.publicationPlace);
			setPublicationDate(metadata.monographicInfo.publicationDate);
			setMonographicActors(metadata.monographicInfo.actors);
			setISBN(metadata.monographicInfo.isbn);
			setEISBN(metadata.monographicInfo.eisbn);
			setEISSN(metadata.monographicInfo.eissn);
			setISSN(metadata.monographicInfo.issn);
			setPages(metadata.monographicInfo.pages);
			setIssueNo(metadata.monographicInfo.issueNo);

			setSummary(metadata.profileDesc.summary);
			setKeywords(Array.from(new Set(metadata.profileDesc.keywords)));
			setLinks(metadata.profileDesc.links);
			setExternalId(metadata.externalId);
			setExternalUrl(metadata.externalUrl);
			setMonographicTitle(metadata.monographicInfo.title);
			setAnalyticTitle(metadata.analyticInfo.title);
		}
	}, [metadata, currentLang]);

	const AnalyticActorsCodes = new Set(findCodes(AnalyticActors));
	const MonographicActorsCodes = new Set(findCodes(MonographicActors));

	return (
		<section className={styles["transcription__section"]}>
			<div className={styles["transcription__main"]}>
				<div className={styles["transcription__main--button-wrapper"]}>
					<Button
						onClick={toggle}
						variant='text'
					>
						{collapsed ? t('transcription.details.expand') : t('transcription.details.collapse')}
						{collapsed ? <i className='icon-chevron-down' /> : <i className='icon-chevron-up' />}
					</Button>
				</div>
				{collapsed && analyticTitle &&
					<div className={styles["transcription__meta"]}>
						{t('transcription.details.title')}
						<span className={ styles["transcription__meta--value"] }>{analyticTitle}</span>
					</div>}
				{collapsed && TranscriptionData.authorsLabel &&
					<div className={styles["transcription__meta"]}>
						{t('transcription.details.author')}
						<span className={styles["transcription__meta--value"]}>{TranscriptionData.authorsLabel}</span>
					</div>}
				<div className={
					collapsed ? styles["transcription__main--collapsed"] : styles["transcription__main"]
				}
				>
					{(analyticTitle || AnalyticActors.length > 0 || analyticLanguage) &&
						<div className={styles["transcription__description"]}>
							<div className={styles["transcription__description--title"]}>
								{t('transcription.details.analyticLevel')}
							</div>
							<div>
								{t('transcription.details.analyticLevelDescription')}
							</div>
						</div>}
					{analyticTitle &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.title')}
							<span className={ styles["transcription__meta--value"] }>{analyticTitle}</span>
						</div>}
					{AnalyticActorsCodes &&
						<>
							{AnalyticActorsCodes.map(element => {
								let newActors = roleFilter(AnalyticActors, element);
								return (
									<div
										className={styles["transcription__meta"]}
										key={element}
									>
										{newActors[0].role[currentLang]}:
										<span className={styles["transcription__meta--value"]}>{newActors.map((item, id) => {
											return (
												(id !== newActors.length - 1) ? (item.name + "; ") : (item.name)
											);
										})}
										</span>
									</div>
								);
							})}
						</>}
					{analyticLanguage &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.language')}
							<span className={styles["transcription__meta--value"]}>{analyticLanguage}</span>
						</div>}
					{(
						monographicTitle 
						|| monographicLanguage 
						|| Type 
						|| signature 
						|| MonographicActorsCodes.size > 0
						|| Chapter 
						|| Volume 
						|| PageRange 
						|| PublicationDate 
						|| PublicationPlace 
						|| Publisher 
						|| ISBN 
						|| Pages 
						|| EISSN 
						|| ISSN 
						|| EISBN 
						|| IssueNo
					) &&
						<div className={styles["transcription__description"]}>
							<div className={styles["transcription__description--title"]}>
								{t('transcription.details.monographicLevel')}
							</div>
							<div>
								{t('transcription.details.monographicLevelDescription')}
							</div>
						</div>}

					{monographicTitle &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.title')}
							<span className={styles["transcription__meta--value"]}>{monographicTitle}</span>
						</div>}

					{Type &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.type')}
							<span className={styles["transcription__meta--value"]}>{Type}</span>
						</div>}
					{signature &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.form.signature')}
							<span className={styles["transcription__meta--value"]}>{signature}</span>
						</div>}
					{MonographicActorsCodes &&
						<>
							{MonographicActorsCodes.map(element => {
								let newActors = roleFilter(MonographicActors, element);
								return (
									<div
										className={styles["transcription__meta"]}
										key={element}
									>
										{newActors[0].role[currentLang]}:
										<span className={styles["transcription__meta--value"]}>
											{newActors.map((item, id) => (id !== newActors.length - 1) ? (item.name + "; ") : (item.name))}
										</span>
									</div>
								);
							})}
						</>}
					{monographicLanguage &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.language')}
							<span className={styles["transcription__meta--value"]}>{monographicLanguage}</span>
						</div>}
					{Chapter &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.numberOfChapter')}
							<span className={styles["transcription__meta--value"]}>{Chapter}</span>
						</div>}
					{Volume &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.volumeNumber')}
							<span className={styles["transcription__meta--value"]}>{Volume}</span>
						</div>}
					{IssueNo &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.numberOfNotebook')}
							<span className={styles["transcription__meta--value"]}>{IssueNo}</span>
						</div>}
					{PageRange &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.pageRange')}
							<span className={styles["transcription__meta--value"]}>{PageRange}</span>
						</div>}
					{Pages &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.pages')}
							<span className={styles["transcription__meta--value"]}>{Pages}</span>
						</div>}
					{Publisher &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.publisher')}
							<span className={styles["transcription__meta--value"]}>{Publisher}</span>
						</div>}
					{PublicationPlace &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.placeOfPublication')}
							<span className={styles["transcription__meta--value"]}>{PublicationPlace}</span>
						</div>}
					{PublicationDate &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.dateOfPublication')}
							<span className={styles["transcription__meta--value"]}>{PublicationDate}</span>
						</div>}
					{ISBN &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.isbn')}
							<span className={styles["transcription__meta--value"]}>{ISBN}</span>
						</div>}
					{EISBN &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.EISBN')}
							<span className={styles["transcription__meta--value"]}>{EISBN}</span>
						</div>}
					{ISSN &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.ISSN')}
							<span className={styles["transcription__meta--value"]}>{ISSN}</span>
						</div>}
					{EISSN &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.EISSN')}
							<span className={styles["transcription__meta--value"]}>{EISSN}</span>
						</div>}
					{(Keywords.length > 0 || Summary || links.length > 0) &&
						<div className={styles["transcription__description"]}>
							<div className={styles["transcription__description--title"]}>{t('transcription.details.textLevel')}</div>
							<div>{t('transcription.details.textLevelDescription')}</div>
						</div>}
					{Keywords.length > 0 &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.keywords')}
							<div className={styles["transcription__meta--value"]}>
								<span className={styles["transcription__meta--links-container"]}>
									{Keywords.map((item, id) =>
										<Link
											key={item}
											className={styles["transcription__meta--link"]}
											to={`${url.transcriptions}?order=last-modification-date&q=${item}&page=1`}
										>
											{item}
											{(id !== links.length - 1) && (<>; </>)}
										</Link>)}
								</span>
							</div>
						</div>}
					{Summary &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.summary')}
							<span className={styles["transcription__meta--value"]}>{Summary}</span>
						</div>}
					{links.length > 0 &&
						<div className={styles["transcription__meta"]}>
							{t('transcription.details.links')}
							<span className={styles["transcription__meta--links-container"]}>
								{links.map((item, index) => (
									<React.Fragment
										key={item}
									>
										<Button
											variant='text'
											onClick={() => window.open(item, '_blank', 'noopener,noreferrer')}
										>
											{item}
											<i className='icon-external' />
											{(index === links.length - 1) || (<>; </>)}
										</Button>
									</React.Fragment>
								))}

							</span>
						</div>}
					{(externalUrl || externalId) &&
						<>
							<div className={styles["transcription__description"]}>
								<div className={styles["transcription__description--title"]}>
									{t('transcription.details.identifierLevel')}
								</div>
							</div>
							{externalUrl &&
								<div className={styles["transcription__meta"]}>
									{t('transcription.details.importAdress')}
									<span
										className='transcription__tooltip'
										data-tooltip-id='custom-tooltip' data-tooltip-content={t('transcription.details.importAdressDesc')}
										data-place='right'
									>
										<i className='icon-question' />
									</span>
									<span className={styles["transcription__meta--value"]}>
										<a
											className={styles["transcription__meta--links"]}
											href={externalUrl}
											target='_blank'
											rel='noopener noreferrer'
										>
											{externalUrl}
										</a>
									</span>
								</div>}
							{externalId &&
								<div className={styles["transcription__meta"]}>
									{t('transcription.details.externalId')}
									<span
										className='transcription__tooltip'
										data-tooltip-id='custom-tooltip' data-tooltip-content={t('transcription.details.externalIdDesc')}
										data-place='right'
									>
										<i className='icon-question' />
									</span>
									<span className={styles["transcription__meta--value"]}>{
										externalId.match(/http(s)?:\/\/.*/) ? (
											<a
												className={styles["transcription__meta--links"]}
												href={externalId}
												target='_blank'
												rel='noopener noreferrer'
											>
												{externalId}
											</a>
										) : (
											externalId
										)
									}
									</span>
								</div>}
						</>}
				</div>
			</div>
		</section>

	);
};

TranscriptionDetailsMetadata.propTypes = {
	metadata: PropTypes.object,
	viewMode: PropTypes.string,
};

export default React.memo(TranscriptionDetailsMetadata);
