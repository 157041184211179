import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styles from '@Pages/Transcription/TranscriptionPermissions/TranscriptionPermissions.module.sass';

const TranscriptionPermissionsProjectInfo = ({title, creator}) => {
	const { t } = useTranslation();

	return (
		<section className={styles["permissions__project-info"]}>
			<div>
				{t('transcription.permissions.projectName')}
				<span>{ title }</span>
			</div>
			<div>
				{t('transcription.permissions.author')}
				<span>{ creator }</span>
			</div>
		</section>
	);
};

TranscriptionPermissionsProjectInfo.propTypes = {
	title: PropTypes.string,
	creator: PropTypes.string,
};

export default TranscriptionPermissionsProjectInfo;