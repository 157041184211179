import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import api from "@/services/api";
import { appActions } from "@/store/actions/app.actions";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alertActions } from "@/store/actions/alert.actions";

import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import styles from "@Pages/Event/Event.module.sass";

const ModalResetTeamA = ({modalVisible, setModalVisible, eventId, teamForModal, setTeams, getEvent, dataToSend}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
    
	const deleteTeam = async () => {
		let data = dataToSend.filter(team => team.name != null);
		data[0].memberEmails = [];

		try {
			const res = await api.put(`/events/${eventId}/teams`, data);

			if (res.status === 200) {
				dispatch(appActions.setLoading(false));
				setTeams(res.data);
				getEvent();
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			dispatch(alertActions.setAlert({
				type: "danger",
				icon:  "icon-circle-warning-empty",
				text: t('events.teams.deleteTeam.warning'),
				close: true,
			}));
		}
	};

	return (
		<ModalWrapperChildren
			modalVisible={modalVisible}
			setModalVisible={() => setModalVisible(false)}
			title={t('events.teams.resetTeamA.title')}
			closeButtonName={t('events.teams.resetTeamA.cancel')}
			confirmButtonName={t('events.teams.resetTeamA.close')}
			onConfirm={() => {deleteTeam(teamForModal), setModalVisible(false);}}
			withFooter
		>
			<div className={ styles["teams__delete-team"] }>
				<span>
					{t('events.teams.resetTeamA.description')}
				</span>
			</div>
		</ModalWrapperChildren>
	);
};

ModalResetTeamA.propTypes = {
	modalVisible: PropTypes.bool, 
	setModalVisible: PropTypes.func, 
	eventId: PropTypes.string,
	teamForModal: PropTypes.string, 
	setTeams: PropTypes.func, 
	getEvent: PropTypes.func,
	dataToSend: PropTypes.array,
};

export default ModalResetTeamA;