import React from 'react';
import PropTypes from 'prop-types';
import Calendar from  '@Assets/images/calendar.svg';
import styles from '@Components/Events/Timeline/Timeline.module.sass';
import moment from 'moment';
	
const Timeline = ({startDate, endDate, type}) => {

	const convertDateMobile = (date) => {
		moment.locale('pl');
		const inputFormat = "YYYY-MM-DD HH:mm";
		const outputFormat = "DD-MM-YYYY, HH:mm";
		// const outputFormat = "DD MMMM YYYY, HH:mm";

		const momentObj = moment(date, inputFormat);

		return momentObj.format(outputFormat);
	};

	const convertDate = (date) => {
		moment.locale('pl');
		const inputFormat = "YYYY-MM-DD HH:mm";
		const outputFormat = "DD MMMM YYYY, HH:mm";

		const momentObj = moment(date, inputFormat);

		return momentObj.format(outputFormat);
	};

	return (
		<div className={ styles[`timeline__content`] }>
			<img className={ styles[`timeline__content__calendar`] } src={Calendar} alt='Date' />
			<div className={ `${styles['timeline__content__date']} ${styles['timeline__content__date--mobile']}` }>
				<span>{convertDateMobile(startDate)}</span>
				<span>{convertDateMobile(endDate)}</span>
			</div>
			<div className={ `${styles['timeline__content__date']} ${styles['timeline__content__date--desktop']} ${type === 'row'  && styles['timeline__content__date--row']}` }>
				<span>{convertDate(startDate)}</span>
				<div>
					<span>- </span>
					<span>{convertDate(endDate)}</span>
				</div>
			</div>
		</div>
	);
};

Timeline.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	type: PropTypes.string,
};

export default Timeline;