import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col } from "react-bootstrap";

import api from "@/services/api";

import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";

import "@Styles/_static-page.sass";

const Regulations = () => {

	const currentLang = useSelector((state) => state.app.currentLang);
	const { t } = useTranslation();

	const [ regulationsText, setRegulationsText ] = useState(null);
	const [ regulationsLoading, setRegulationsLoading ] = useState(true);
    
	useEffect(() => {
		setRegulationsLoading(true);

		api.get('/static-files/regulations')
			.then(response => {
				setRegulationsLoading(false);
				setRegulationsText(response.data);
			});
	}, []);

	const breadcrumbsList = [
		{ label: "home", id: 1 },
		{ label:t('staticPage.regulations') , id: 2 },
	];

	return (
		<main className='static-page'>
			<Container>
				<Breadcrumbs breadcrumbsList={ breadcrumbsList } />
				<section className='static-page__content'>
					<Row>
						<Col>
							{ regulationsText &&
								<ReactMarkdown source={ regulationsText[currentLang] } />}
						</Col>
					</Row>
				</section>
			</Container>
		</main>
	);
};

export default Regulations;