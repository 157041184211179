import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { reverse } from "named-urls";
import { Row, Col, Form, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";

import api from "@/services/api";
import url from "@/router/urls";

import { alertActions } from "@/store/actions/alert.actions";
import { authActions } from "@/store/actions/auth.actions";

import Breadcrumbs from "@Elements/Breadcrumbs/Breadcrumbs";
import FormInputPassword from "@Elements/Form/FormInputPassword";
import FormInputPasswordRepeated from "@Elements/Form/FormInputPasswordRepeated";
import FormSubmit from "@Elements/Form/FormSubmit";
import Button from "@Elements/Button/Button";
import Alert from "@Elements/Alert/Alert";

import styles from "@Pages/Auth/Profile/Profile.module.sass";

import ModalWrapperChildren from '@Elements/Modal/ModalWrapperChildren';
import ModalDeleteUser from '@Elements/Modal/parts/ModalDeleteUser';

const MyGroupItem = ({ group, index }) => {
	const { t } = useTranslation();
	return (
		<div
			key={group.name + index}
			className={styles["account__adminlist--item"]}
		>
			<div className={styles["account__adminlist--item-lp"]}>{index + 1}.</div>
			<div className={styles["account__adminlist--item-name"]}>{group.name}</div>
			<div className={styles["account__adminlist--item-button"]}>
				<Link to={reverse(url.auth.group.edit, { id: group.id })}>
					<Button
						variant='text'
					>
						{t('auth.account.groupEdit')}
					</Button>
				</Link>
			</div>
		</div>
	);
};
MyGroupItem.propTypes = {
	group: PropTypes.object,
	index: PropTypes.number,
};

const OtherGroupItem = ({ group, index }) => {
	const { t } = useTranslation();
	return (
		<li
			key={group.name + index}
			className={styles["account__list--item"]}
		>
			{index + 1}. {group.name}&nbsp;<span> ({t('auth.account.groupCreator')}: {group.creator.email})</span>
		</li>
	);
};
OtherGroupItem.propTypes = {
	group: PropTypes.object,
	index: PropTypes.number,
};

const Profile = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);
	const accountUrl = useSelector(state => state.app.config.accountUrl);

	const [UserEmail, setUserEmail] = useState("");
	const [UserType, setUserType] = useState("USER");
	const [UserMyGroups, setUserMyGroups] = useState([]);
	const [UserHasPassword, setUserHasPassword] = useState(false);
	const [UserOtherGroups, setUserOtherGroups] = useState([]);
	const [UserQuota, setUserQuota] = useState(0);
	const [UserUsedSpaceQuota, setUserUsedSpaceQuota] = useState(0);
	const [UserPasswordForm, setUserPasswordForm] = useState(false);
	const [UserPasswordFormSubmit, setUserPasswordFormSubmit] = useState(false);

	const [isModalDeleteUser, setIsModalDeleteUser] = useState(null);

	const breadcrumbsList = [
		{ id: 1, label: "home" },
		{ id: 2, label: t('breadcrumbs.accountMyProfile') },
	];

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
		setError,
		getValues,
	} = useForm({
		defaultValues: {
			currentPassword: "",
			newPassword: "",
			repeatedNewPassword: "",
		},
		mode: "onChange",
	});

	const onSubmit = data => {
		setUserPasswordFormSubmit(true);
		api
			.put("/users/my-password", {
				...data,
			})
			.then(() => {
				setUserPasswordFormSubmit(false);
				setUserPasswordForm(false);

				dispatch(
					alertActions.setAlert({
						type: "success",
						icon: "icon-accept",
						text: "auth.account.passwordChangeAlert",
						close: true,
					}),
				);
			})
			.catch(error => {
				setUserPasswordFormSubmit(false);
				for (const errorField in error.data) {
					const errorCode = error.data[errorField].split(".")[2];
					setError(errorField, { type: errorCode, message: t(`form.messages.${errorCode}`) });
				}
			});
	};

	useEffect(() => {
		if (isLoggedIn === true) {
			api
				.get("/users/my-profile")
				.then(response => {
					const { user, myGroups, otherGroups } = response.data;

					setUserEmail(user.email);
					setUserType(user.securityGroup);
					setUserHasPassword(user.hasPassword);
					setUserMyGroups(myGroups);
					setUserOtherGroups(otherGroups);
					setUserQuota(user.quota);
					setUserUsedSpaceQuota(user.usedSpaceQuota);
				});
		}
	}, [isLoggedIn]);

	const onDeleteUserAccepted = async (removePublished) => {
		dispatch(authActions.deleteUser(removePublished));
		setIsModalDeleteUser(false);
	};

	return (
		<>
			<ModalWrapperChildren
				modalVisible={isModalDeleteUser}
				setModalVisible={setIsModalDeleteUser}
				title={t('auth.account.removeAccount')}
			>
				{modalProps => (
					<ModalDeleteUser
						modalProps={modalProps}
						onDeleteUserAccepted={onDeleteUserAccepted}
					/>
				)}
			</ModalWrapperChildren>

			<main className={styles["account"]}>
				<Container>
					<Breadcrumbs breadcrumbsList={breadcrumbsList} />
					<div className={styles["account__block"]}>
						<Alert />
						<div className='block__header'>
							<h2>{t('auth.account.title')}</h2>
						</div>
						<div className='block__content'>
							<div className={styles["account__info"]}>
								{UserType !== "USER" && (
									<>
										<div className={styles["account__table"]}>
											<div className={styles["account__table--row"]}>
												<div className={styles["account__table--label"]}>
													<strong>{t('auth.account.function')}:</strong>
												</div>
												<div className={styles["account__table--value-split"]}>
													<span>{t(`functionList.${UserType}`)}</span>
												</div>
											</div>
										</div>
										<hr />
									</>
								)}
								<div className={styles["account__table"]}>
									<div className={styles["account__table--row"]}>
										<div className={styles["account__table--label"]}>
											<strong>{t('auth.account.email')}:</strong>
										</div>
										<div className={styles["account__table--value"]}>
											<span>{UserEmail}</span>
										</div>
									</div>

									{(!UserPasswordForm && UserHasPassword) && (
										<div className={styles["account__table--row"]}>
											<div className={styles["account__table--label"]}>
												<strong>{t('auth.account.password')}:</strong>
											</div>
											<div className={styles["account__table--value"]}>
												<Button
													variant='text'
													onClick={() => setUserPasswordForm(true)}
												>
													{t('auth.account.changePassword')}
												</Button>
											</div>
										</div>
									)}
								</div>
								{accountUrl && (
									<>
										<hr />
										<div className={styles["account__table"]}>
											<div className={styles["account__table--row"]}>
												<div className={styles["account__table--label"]}>
													<strong>{t('auth.account.manageSSOAccount')}:</strong>
												</div>
												<div className={styles["account__table--value-split"]}>
													<a href={ accountUrl }>{t('auth.account.manageSSOAccountLink')}</a>
												</div>
											</div>
										</div>
									</>
								)}
							</div>

							{UserPasswordForm && (
								<>
									<div className={styles["account__password"]}>
										<hr />
										<div className={styles["account__password-form"]}>
											<h3 className='text--heading'>{t('auth.account.passwordChange')}</h3>

											<form
												onSubmit={handleSubmit(onSubmit)}
												noValidate
												className='auth-form__fields auth-form__fields--small-margin'
											>
												<Form.Group controlId='currentPassword'>
													<FormInputPassword
														register={register}
														errors={errors}
														name='currentPassword'
														novalidate
													/>
												</Form.Group>

												<Form.Group controlId='newPassword'>
													<FormInputPassword
														register={register}
														errors={errors}
														name='newPassword'
													/>
												</Form.Group>

												<div className='auth-form__passwordtip'>
													<span className='text--gray text--small'>{t('form.passwordTip')}</span>
												</div>

												<Form.Group controlId='repeatedNewPassword'>
													<FormInputPasswordRepeated
														register={register}
														errors={errors}
														getValues={getValues()}
														name='repeatedNewPassword'
													/>
												</Form.Group>

												<Form.Group
													controlId='registerFormSubmit'
													className='auth-submit'
												>
													<Row className='auth-submit__row'>
														<Col className='auth-submit__col--tobutton'>
															<Button
																variant='link'
																type='button'
																onClick={() => setUserPasswordForm(false)}
															>
																{t('form.cancel')}
															</Button>
														</Col>
														<Col sm='auto'>
															<FormSubmit
																label={t('form.change')}
																className='auth-submit__button'
																variant='submit'
																disabled={
																	UserPasswordFormSubmit ||
																	!isDirty ||
																	!isValid
																}
															/>
														</Col>
													</Row>
												</Form.Group>
											</form>
										</div>
									</div>
								</>
							)}
							<hr />
							<div className={styles["account__remove--wrapper"]}>
								<Button
									variant='text'
									onClick={() => setIsModalDeleteUser(true)}
									className={styles["account__remove--button"]}
								>
									{t('auth.account.removeAccount')}
								</Button>
							</div>
							<div className={styles["account__groups"]}>
								<div className={styles["account__groups--title"]}>
									<h2>{t('auth.account.myGroup')}</h2>
									<Link
										to={url.auth.group.new}
									>
										<Button
											variant='text'
											type='button'
										>
											{t('auth.account.createNewGroup')}
										</Button>
									</Link>
								</div>
								{UserMyGroups.length > 0 && (
									<div className={styles["account__groups--admin"]}>
										<h3 className='text--heading'>{t('auth.account.groupAdministrator')}:</h3>
										<div className={styles["account__adminlist"]}>
											{UserMyGroups.map((group, index) => <MyGroupItem key={group.id} group={group} index={index} />)}
										</div>
									</div>
								)}
								{UserOtherGroups.length > 0 && (
									<div className={styles["account__groups--rest"]}>
										<h3 className='text--heading'>{t('auth.account.groupRest')}:</h3>
										<ul className={styles["account__list"]}>
											{UserOtherGroups.map((group, index) => <OtherGroupItem key={group.id} group={group} index={index} />)}
										</ul>
									</div>
								)}
								{!(UserMyGroups.length > 0 || UserOtherGroups.length > 0) && (
									<div className={styles["account__groups--blank"]}>{t('auth.account.groupBlank')}</div>
								)}
							</div>
						</div>
					</div>
				</Container>
			</main>
		</>
	);
};

export default Profile;