import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import { Dropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { reverse } from 'named-urls';
import url from "@/router/urls";
import api from "@/services/api";
import { appActions } from "@/store/actions/app.actions";
import { alertActions } from "@/store/actions/alert.actions";

import Button from '@Elements/Button/Button';
import Alert from "@Elements/Alert/Alert";
import { CheckViewModePermission } from "@/permissions/ViewModes";

import ModalBaseCancelConfirm from '@Elements/Modal/ModalBaseCancelConfirm';

import styles from "@Pages/Transcription/TranscriptionDetails/TranscriptionDetails.module.sass";

const TranscriptionDetailsNav = ({
	viewMode,
	transcriptionId,
	title,
	hidden,
	creationDate,
	creationMode,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [showModalPublish, setShowModalPublish] = useState(false);
	const [showModalUnpublish, setShowModalUnpublish] = useState(false);
	const [showModalRemoveTranscription, setShowModalRemoveTranscription] = useState(false);

	const onEditTranscription = (publish) => {
		if (publish && creationMode !== 'web-form') {
			setShowModalPublish(true);
			return;
		}
		const location = reverse(url.transcription.edit, { transcriptionId: parseInt(transcriptionId) });
		let query = "";
		if (publish) {
			query = "?publish=true";
		}
		history.push(location + query);
	};

	const modalCommitPublish = (publish) => {
		dispatch(appActions.setLoading(true));
		api.put(`/transcriptions/${transcriptionId}/hidden`, { "hidden": !publish })
			.then(() => {
				history.go(0);
			})
			.catch(() => {
				dispatch(
					alertActions.setAlert({
						type: "danger",
						icon: "icon-circle-warning-empty",
						text: "transcription.details.transcriptionChangeError",
						close: true,
					}),
				);
			})
			.finally(() => {
				setShowModalPublish(false);
				setShowModalUnpublish(false);
				dispatch(appActions.setLoading(false));
			});
	};

	const modalCommitRemoveTranscription = async () => {
		dispatch(appActions.setLoading(true));
		try {
			api.delete(`/transcriptions/${transcriptionId}`);
			history.replace(url.transcriptionsUser);
		}
		catch (error) {
			dispatch(
				alertActions.setAlert({
					type: "danger",
					icon: "icon-circle-warning-empty",
					text: "transcription.details.transcriptionChangeError",
					close: true,
				}),
			);
		}
		finally {
			dispatch(appActions.setLoading(false));
		}
	};

	return (
		<>
			{/* Modal remove transcription */}
			<ModalBaseCancelConfirm
				modalVisible={showModalRemoveTranscription}
				setModalVisible={setShowModalRemoveTranscription}
				title={t('modals.deleteTranscription.title')}
				bodyHTML={t('modals.deleteTranscription.bodyHTML')}
				cancelButtonName={t('form.cancel')}
				confirmButtonName={t('modals.deleteTranscription.button')}
				onConfirm={modalCommitRemoveTranscription}
			/>

			{/* Modal publish transcription */}
			<ModalBaseCancelConfirm
				modalVisible={showModalPublish}
				setModalVisible={setShowModalPublish}
				title={t('modals.publishTranscription.title')}
				body={t('modals.publishTranscription.bodyHTML')}
				cancelButtonName={t('form.cancel')}
				confirmButtonName={t('modals.publishTranscription.button')}
				onConfirm={() => modalCommitPublish(true)}
			/>

			{/* Modal unpublish transcription */}
			<ModalBaseCancelConfirm
				modalVisible={showModalUnpublish}
				setModalVisible={setShowModalUnpublish}
				title={t('modals.depublishTranscription.title')}
				body={t('modals.depublishTranscription.bodyHTML')}
				cancelButtonName={t('form.cancel')}
				confirmButtonName={t('modals.depublishTranscription.button')}
				onConfirm={() => modalCommitPublish(false)}
			/>

			<div className={styles["transcription__header"]}>
				<div className={styles["transcription__title--wrapper"]}>
					<h2>{title}</h2>
					{(viewMode === 'admin') && (
						<>
							<div className={styles["transcription__desktopNav"]}>
								<div
									className='btn-group'
									role='group'
								>
									{CheckViewModePermission("canRemoveTranscription", viewMode) &&
										<Button
											variant='secondary'
											className={styles["transcription__link"]}
											onClick={() => setShowModalRemoveTranscription(true)}
										>
											{t('transcription.details.remove')}
										</Button>}

									{CheckViewModePermission("canEditTranscription", viewMode) &&
										<Button
											variant='primary'
											onClick={() => onEditTranscription(false)}
											disabled={ creationMode !== 'web-form'}
										>
											{t('transcription.details.edit')}
										</Button>}

									{CheckViewModePermission("canPublishTranscription", viewMode) && hidden &&
										<Button
											variant='primary'
											onClick={() => onEditTranscription(true)}
										>
											{t('transcription.details.public')}
										</Button>}

									{CheckViewModePermission("canUnpublishTranscription", viewMode) && !hidden &&
										<Button
											variant='primary'
											onClick={() => setShowModalUnpublish(true)}
										>
											{t('transcription.details.reversePublic')}
										</Button>}
								</div>
							</div>

							<Dropdown className={styles["navigation__container"]}>
								<Dropdown.Toggle id='details' className={styles["navigation__toggle"]}>
									<span className={styles["navigation__icon"]} >&nbsp;</span>
								</Dropdown.Toggle>
								<Dropdown.Menu className={styles["transcription__mobileNav--dropdown-menu"]}>
									{CheckViewModePermission("canRemoveTranscription", viewMode) &&
										<Dropdown.Item
											onClick={() => setShowModalRemoveTranscription(true)}
										>
											{t('transcription.details.remove')}
										</Dropdown.Item>}
									{CheckViewModePermission("canEditTranscription", viewMode) &&
										<Dropdown.Item
											onClick={() => onEditTranscription(false)}
											disabled={ creationMode !== 'web-form'}
										>
											{t('transcription.details.edit')}
										</Dropdown.Item>}
									{CheckViewModePermission("canPublishTranscription", viewMode) && hidden &&
										<Dropdown.Item
											onClick={() => onEditTranscription(true)}
										>
											{t('transcription.details.public')}
										</Dropdown.Item>}
									{CheckViewModePermission("canUnpublishTranscription", viewMode) && !hidden &&
										<Dropdown.Item
											onClick={() => setShowModalUnpublish(true)}
										>
											{t('transcription.details.reversePublic')}
										</Dropdown.Item>}
								</Dropdown.Menu>
							</Dropdown>
						</>
					)}
				</div>
				<Alert alertBoxName='titleBox' />

				<div className={styles["transcription__metadata"]}>
					<h3>
						{t('transcription.details.metadata')}
					</h3>
					<div>
						{t('transcription.details.dateOfCreation')}{creationDate}
					</div>
				</div>
			</div >
		</>
	);
};

TranscriptionDetailsNav.propTypes = {
	viewMode: PropTypes.string,
	transcriptionId: PropTypes.number,
	title: PropTypes.string,
	hidden: PropTypes.bool,
	creationDate: PropTypes.string,
	creationMode: PropTypes.string,
};

export default TranscriptionDetailsNav;
