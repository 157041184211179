import React, { memo, useCallback } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import { appActions } from '@/store/actions/app.actions';

import styles from '@Templates/header/AppHeader/HeaderTopLang.module.sass';

const HeaderTopLang = ({ isMobile }) => {
	const langs = useSelector((state) => state.app.langs);
	const currentLang = useSelector((state) => state.app.currentLang);
	let { id: currenLangId } = langs.filter(item => item.id === currentLang)[0];
	const dispatch = useDispatch();

	const setLanguage = useCallback((lang) => {
		dispatch(appActions.setLang(lang));
	}, [dispatch]);

	return (
		<>
			{!isMobile ? (
				<div className={styles["lang__select--wrapper"]}>
					{langs.map((lang, index) =>
						<React.Fragment key={lang.id}>
							<div
								onClick={() => setLanguage(lang)}
								className={styles[lang.id === currentLang ? 'lang__select--button--active' : 'lang__select--button']}
							>
								{lang.id === currentLang ? <i className='icon-accept' /> : null}
								{lang.name}
							</div>
							{index < langs.length - 1 ? <div>|</div> : null}
						</React.Fragment>)}
				</div>
			) : (
				<Dropdown className={styles["lang-dropdown"]} alignRight>
					<Dropdown.Toggle
						id='dropdown-custom-1'
						className={styles["lang-dropdown__button"]}
						variant={styles["lang-dropdown__button"]}
					>{currenLangId.toUpperCase()}
					</Dropdown.Toggle>

					<Dropdown.Menu className={styles["lang-dropdown__menu"]}>
						{langs.map(lang =>
							<Dropdown.Item
								key={lang.id}
								onClick={() => setLanguage(lang)}
								className={` 
						${styles["lang-dropdown__item"]}
						${lang.id !== currentLang ? styles["lang-dropdown__item--not-active"] : ''} 
					` }
							>
								{lang.id === currentLang ? <i className='icon-accept' /> : null}
								{lang.id.toUpperCase()}
							</Dropdown.Item>,
						)}
					</Dropdown.Menu>
				</Dropdown>
			)}
		</>

	);
};
HeaderTopLang.propTypes = {
	isMobile: PropTypes.bool,
};

export default memo(HeaderTopLang);
