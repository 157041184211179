import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import api from "@/services/api";
import { appActions } from "@/store/actions/app.actions";

import FormSubmit from "@Elements/Form/FormSubmit";
import FormInputFile from "@Elements/Form/FormInputFile";
import FormInputCustom from "@Elements/Form/FormInputCustom";
import Button from "@Elements/Button/Button";
import Alert from "@Elements/Alert/Alert";

import CommonDropdown from '@Elements/Dropdown/CommonDropdown';
import { alertActions } from "@/store/actions/alert.actions";

import modalStyles from '@Elements/Modal/parts/ModalDropdown.module.sass';
import styles from '@Elements/Modal/parts/ModalAddPages.module.sass';

const ModalAddPages = ({ modalProps, transcriptionId }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const history = useHistory();

	const [selectedOption, setSelectedOption] = useState(1);

	const options = [
		{ value: 'IIIF', label: t('form.importSelect.importIIIF') },
		{ value: 'LOCAL', label: t('form.importSelect.importLocal') },
	];

	const { register, handleSubmit, formState: { errors, isValid }, setError, watch } = useForm({
		defaultValues: {
			importLink: "",
		},
		mode: "onChange",
	});

	const inputFile = watch('file');
	const formats = [
		'.zip',
		'.tiff',
		'.tif',
		'.pdf',
		'.jpeg',
		'.jpg',
		'.djv',
		'.djvu',
		'.png',
	];

	const dispatchAlertAction = useCallback((text) => {
		dispatch(
			alertActions.setAlert({
				type: "danger",
				icon: "icon-circle-warning-empty",
				text: text,
				close: true,
				target: 'addPageBox',
			}),
		);
	}, [dispatch]);

	const sendFiles = (data) => {
		const formData = new FormData();
		formData.append('file', data.file[0]);
		dispatch(appActions.setLoading(true));
		api.post(`transcriptions/${transcriptionId}/add-pages`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
			.then(() => {
				history.go(0);
				handleOnClose();
				dispatch(appActions.setLoading(false));
			}).catch((response) => {
				dispatch(appActions.setLoading(false));
				dispatch(
					alertActions.setAlert({
						type: "danger",
						icon: "icon-circle-warning-empty",
						text: "form.messages.fileCouldNotBeAdded",
						close: true,
						target: 'addPageBox',
					}),
				);
				if (response && response.data && response.data.code) {
					switch (response.data.code) {
						case 'pages.error.wrongFileType': {
							const files = response.data.params;
							const message = files.length > 1 ? `${t('form.messages.wrongFilesType')}: ${files.join(', ')}` : `${t('form.messages.wrongFileType')}: ${files[0]}`;
							setError('file', { type: 'wrongFilesType', message });
							break;
						}
						case 'pages.error.fileAlreadyExists': {
							const files = response.data.params;
							const message = files.length > 1 ? `${t('form.messages.filesAlreadyExist')}: ${files.join(', ')}` : `${t('form.messages.fileAlreadyExists')}: ${files[0]}`;
							setError('file', { type: 'fileAlreadyExists', message });
							break;
						}
						case 'pages.error.fileTooLarge': {
							dispatchAlertAction("form.messages.fileTooLarge");
							break;
						}
						case 'quota.error.user.exceeded': {
							dispatchAlertAction("form.messages.quotaExceeded");
							break;
						}
						case 'quota.error.translation.exceeded': {
							dispatchAlertAction("form.messages.quotaTranscriptionExceeded");
							break;
						}
						case 'pages.error.iiif-tools': {
							dispatchAlertAction("form.messages.fileCannotBeProcessed");
							break;
						}
						default: {
							dispatchAlertAction(response.data.code);
						}
					}
				}
			});
	};

	const importIIIF = (url) => {
		dispatch(appActions.setLoading(true));
		api.post(`transcription/${transcriptionId}/import-iiif?resourceURL=${url}`)
			.then(() => {
				history.go(0);
				handleOnClose();
				dispatch(appActions.setLoading(false));
			})
			.catch((response) => {
				dispatch(appActions.setLoading(false));
				dispatch(
					alertActions.setAlert({
						type: "danger",
						icon: "icon-circle-warning-empty",
						text: "form.messages.fileCouldNotBeAdded",
						close: true,
						target: 'addPageBox',
					}),
				);
				if (response && response.data && response.data.resourceUrl) {
					switch (response.data.resourceUrl) {
						case 'imports.error.url.not-found': {
							setError('importLink', { type: 'error', message: t('transcription.import.errors.' + response.data.resourceUrl.replace(/[.-]/g, "")) });
							break;
						}
						case 'imports.text.format.link-less': {
							setError('importLink', { type: 'error', message: t('transcription.import.errors.' + response.data.resourceUrl.replace(/[.-]/g, "")) });
							break;
						}
						default: {
							dispatchAlertAction(response.data.code);
						}
					}
				}
			});

	};

	const handleOnClose = () => modalProps.setModalVisible(false);

	const onSubmit = data => {
		if (data.importLink) {
			importIIIF(data.importLink);
		}
		else if (data.file) {
			sendFiles(data);
		}
	};

	const errorMessage = () => {
		return errors.file.type === 'wrongFileType' ? t('form.messages.wrongFileType') : errors.file.message;
	};

	return (
		<>
			<Modal.Body>
				<Alert alertBoxName='addPageBox' />
				<p style={{ fontSize: "0.9375rem" }}>
					{t('transcription.addPages.description')}
				</p>
				<CommonDropdown
					options={options.map(item => item.label)}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					className={modalStyles['modal-dropdown']}
				/>

				<form
					style={{ "marginTop": "28px" }}
					className='block__form'
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className='block__form--row'>
						{selectedOption === 0 ?
							<FormInputCustom
								name='importLink'
								label={t('form.inputIIIF.label')}
								placeholder={t('form.inputIIIF.placeholder')}
								register={register}
								validateData={{
									required: {
										value: true,
										message: t('form.messages.emptyUrl'),
									},
									pattern: { // eslint-disable-line
										value: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/, // eslint-disable-line
										message: t('form.messages.invalidUrl'),
									},
								}}
								type='text'
								errorMessage={errors.importLink ? errors.importLink.message : ''}
							/>
							:
							<>
								<FormInputFile
									dict={null}
									register={register}
									errorMessage={errors.file && errorMessage()}
									formats={formats}
									chooseFile={inputFile && Object.values(inputFile).map((value) => { return value.name; }).toString()}
								/>
								<div className={styles['addpages__info']}><i className='icon-info'/>{t('modals.addPages.quotaInfo')}</div>
							</>}
					</div>

					<div className={styles['addpages__additional']}>{t('modals.addPages.additional')}</div>

					<div className='block__form--row block__form--buttons'>
						<Button
							variant='secondary'
							onClick={handleOnClose}
							className='block__button--cancel'
						>
							{t('form.cancel')}
						</Button>
						<FormSubmit
							label={t('form.inputFile.startSendingFile')}
							className='block__button--submit'
							variant='primary'
							disabled={selectedOption === 0 ?
								!isValid
								:
								!isValid || (inputFile && Object.keys(inputFile).length === 0)}
						/>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer />
		</>
	);
};

ModalAddPages.propTypes = {
	modalProps: PropTypes.object,
	transcriptionId: PropTypes.number,
};

export default ModalAddPages;
