import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import api from "@/services/api";
import { useDispatch } from 'react-redux';
import { appActions } from "@/store/actions/app.actions";
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import TranscriptionsSortSelect from '@Elements/Transcriptions/TranscriptionsSortSelect';
import TranscriptionsMyScopeSelect from '@Elements/Transcriptions/TranscriptionsMyScopeSelect';
import PaginationsItemsPerPage from "@Components/Pagination/PaginationsItemsPerPage";
import styles from "@Pages/EventTranscriptions/EventTranscriptions.module.sass";

const Search = ({activePage, setListTranscriptions}) => {
	const [order, setOrder] = useState('');
	const [scope, setScope] = useState('');
	const [query, setQuery] = useState('');
	const [itemsOnPage, setItemsOnPage] = useState(30);
	const [ debounceQuery ] = useDebounce(query, 500);

	const dispatch = useDispatch();
	const { t } = useTranslation();
    
	const getListTranscriptions = async () => {
		dispatch(appActions.setLoading(true));
		try {
			const res = await api.get(`/transcriptions?${debounceQuery != '' ? `q=${debounceQuery}&` : ''}type=ALL&scope=${scope}&order=${order}&page=${activePage}&perpage=${itemsOnPage}`);

			if (res.status === 200) {
				setListTranscriptions(res.data);
				dispatch(appActions.setLoading(false));
			}
		} catch (error) {
			dispatch(appActions.setLoading(false));
			// eslint-disable-next-line
			console.log(error);
		}
	};

	useEffect(() => {
		getListTranscriptions();
	}, [activePage, order, scope, itemsOnPage, debounceQuery]);

	return (
		<div className={styles['events__search']} >
			<input value={query} onChange={(e) => setQuery(e.target.value)} className={styles['events__search__input']} placeholder={t('events.transcriptions.search.placeholder')} />
			<div className={styles['events__search__selects']}>
				<TranscriptionsMyScopeSelect
					setScope={setScope}
					scope={scope}
				/>
				<TranscriptionsSortSelect
					setOrder={setOrder}
					order={order}
				/>
				<PaginationsItemsPerPage
					count={itemsOnPage}
					setCount={setItemsOnPage}
					title={t('events.transcriptions.search.itemsPerPage')}
				/>
			</div>
		</div>
	);
};

Search.propTypes = {
	activePage: PropTypes.number,
	setListTranscriptions: PropTypes.func,
};

export default Search;