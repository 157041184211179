import React from 'react';
import PropTypes from 'prop-types';
import ModalWrapperChildren from "@Elements/Modal/ModalWrapperChildren";
import styles from "@Pages/Event/Event.module.sass";
import { useTranslation } from 'react-i18next';

const ModalDeleteEvent = ({modalDelete, setModalDelete, deleteEvent}) => {
	const { t } = useTranslation();

	return (
		<ModalWrapperChildren
			modalVisible={modalDelete}
			setModalVisible={() => setModalDelete(false)}
			title={t('events.deleteEvent.confirm')}
			closeButtonName={t('events.deleteEvent.cancel')}
			confirmButtonName={t('events.deleteEvent.confirm')}
			onConfirm={() => {deleteEvent(), setModalDelete(false);}}
			withFooter
		>
			<div className={ styles["teams__delete-event"] } >
				<span>{t('events.deleteEvent.description')}</span>
			</div>
		</ModalWrapperChildren>
	);
};

ModalDeleteEvent.propTypes = {
	modalDelete: PropTypes.bool, 
	setModalDelete: PropTypes.func, 
	deleteEvent: PropTypes.func,
};

export default ModalDeleteEvent;