import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import PropTypes from "prop-types";
import CommonDropdown from '@Elements/Dropdown/CommonDropdown';

import { CheckViewModePermission } from "@/permissions/ViewModes";

import api from "@/services/api";
import Button from "@Elements/Button/Button";

import styles from "@Pages/Transcription/TranscriptionPermissions/TranscriptionPermissions.module.sass";

const TranscriptionPermissionsUserItem = ({
	orderNum,
	email,
	userId,
	role,
	onDeleteUserProps,
	summarizeData,
	viewMode,
	availableRoles,
}) => {
	const { currentLang } = useSelector(state => state.app);
	const { t } = useTranslation();

	const { transcriptionId } = useParams();

	const [selectedRoleIndex, setSelectedRoleIndex] = useState(null);

	useEffect(() => {
		setSelectedRoleIndex(availableRoles.findIndex(item => item.code === role));
	}, [role, availableRoles, currentLang]);

	const changeRole = roleCode => {
		api.put(`/transcriptions/${transcriptionId}/allowed-users`, {
			userId: userId,
			roleCode: roleCode,
		});
	};

	const onDeleteUser = (id) => {
		onDeleteUserProps(id);
	};

	const onSelectedUser = (option) => {
		setSelectedRoleIndex(option);
		if (availableRoles.length) {
			changeRole(availableRoles[option].code);
		}
	};

	const getAdminName = useMemo(() => {
		const adminObject = availableRoles.filter(item => item.code === "admin")[0];
		return adminObject ? adminObject[currentLang] : null;
	}, [availableRoles]);

	return (
		<div
			key={email}
			className={styles['permissions__userlist--item']}
		>
			<div className={styles['permissions__userlist--element']}>
				<span className={styles["permissions__userlist--item-descplaceholder"]}>{t('admin.dashboard.no')}</span>
				{orderNum + 1}
			</div>
			<div className={styles['permissions__userlist--element']}>
				<span className={styles["permissions__userlist--item-descplaceholder"]}>{t('transcription.permissions.emailAddress')}: </span>{email}
				<a href={`mailto:${email}`} className={styles["permissions__userlist--item-desktopbutton"]}>
					<i className='icon-mail' /> {t('transcription.permissions.table.contact')}
				</a>
				<Button
					variant='secondary'
					type='button'
					onClick={() => window.location = 'mailto:yourmail@domain.com'}
					className={styles["permissions__userlist--item-mobilebutton"]}
				>
					{t('transcription.permissions.table.contact')}
				</Button>
			</div>
			<div className={styles['permissions__userlist--element']}>
				<span className={styles["permissions__userlist--item-descplaceholder"]}>Rola: </span>
				{orderNum === 0 && getAdminName}

				{orderNum !== 0 && availableRoles.length && CheckViewModePermission("canChangeUserRole", viewMode) && (
					<CommonDropdown
						options={
							availableRoles.map(it => it[currentLang])
						}
						selectedOption={selectedRoleIndex}
						setSelectedOption={onSelectedUser}
						className={`dropdown-new ${styles['permissions__userlist--mobile-dropdown']}`}
					/>
				)}
				{orderNum !== 0 && availableRoles.length && !CheckViewModePermission("canChangeUserRole", viewMode) && (
					<span style={{ textTransform: "capitalize" }}>{availableRoles.find(item => item.code === role)[currentLang]}</span>
				)}
			</div>
			{summarizeData && (
				<>
					<div className={styles['permissions__userlist--element']}>
						<span className={styles["permissions__userlist--item-descplaceholder"]}>
							{t('transcription.permissions.table.chars')}:&nbsp;
						</span>
						<span>{summarizeData.numberOfInsertedChars}</span>
					</div>
					<div className={styles['permissions__userlist--element']}>
						<span className={styles["permissions__userlist--item-descplaceholder"]}>
							{t('transcription.permissions.table.words')}:&nbsp;
						</span>
						<span>{summarizeData.words}</span>
					</div>
					<div className={styles['permissions__userlist--element']}>
						<span className={styles["permissions__userlist--item-descplaceholder"]}>
							{t('transcription.permissions.table.verses')}:&nbsp;
						</span>
						<span>{summarizeData.accepted}</span>
					</div>
					<div className={styles['permissions__userlist--element']}>
						<span className={styles["permissions__userlist--item-descplaceholder"]}>
							{t('transcription.permissions.table.correctVerses')}:&nbsp;
						</span>
						<span>{summarizeData.corrected}</span>
					</div>
				</>
			)}

			<div className={styles['permissions__userlist--element']}>
				{CheckViewModePermission("canRemoveUser", viewMode) && orderNum !== 0 && (
					<>
						<Button
							variant='text'
							type='button'
							onClick={() => onDeleteUser(userId)}
							className={styles["permissions__userlist--item-desktopbutton"]}
						>
							{t('group.removeUser')}
						</Button>
						<Button
							variant='secondary'
							type='button'
							onClick={() => onDeleteUser(userId)}
							className={styles["permissions__userlist--item-mobilebutton"]}
						>
							{t('group.removeUser')}
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

TranscriptionPermissionsUserItem.propTypes = {
	orderNum: PropTypes.number,
	email: PropTypes.string,
	userId: PropTypes.number,
	role: PropTypes.string,
	summarizeData: PropTypes.object,
	viewMode: PropTypes.string,
	onDeleteUserProps: PropTypes.func,
	availableRoles: PropTypes.array,
};

export default TranscriptionPermissionsUserItem;