import { langs } from '@/store/initialState/langs.initialState';
const appInitialState = {
	langs,
	currentLang: langs[0].id,
	isMourning: false,
	isLoading: false,
	isError: false,
	isNotFound: false,
	cookiePermission: false,
	dict: {},
	viewer: {
		togglePanels: {
			osd: false,
			editor: false,
		},
		swapPanels: false,
		mobilePanels: 'osd',
	},
	OCRProfiles: [],
	modalConfirmInfo: "",
	modalDeleteTranscriptionPage: null,
	modalCommentDeleteCommentId: null,
	importedTranscription: null,
	questionnaireLink: null,
	selectedIndexId: null,
	firstPageId: null,
	config: {
		reactAppApi: null,
		accessibilityStatement: null,
	},
};

export default appInitialState;