import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import url from "@/router/urls";
import api from "@/services/api";

import styles from "@Templates/footer/AppFooter/AppFooter.module.sass";

const AppFooter = () => {
	const { t } = useTranslation();

	const contactUrl = url.contact;
	const privacyPolicyUrl = url.privacyPolicy;

	return (
		<footer className={styles["footer"]} >
			<div className={styles["footer__block--sparse"]}>
				<Link to={contactUrl}>{t('footer.links.contact')}</Link>
				<Link to={privacyPolicyUrl}>{t('footer.links.privacyPolicy')}</Link>
				<a href={privacyPolicyUrl}>{t('footer.shareYourOpinion')}<i className='icon-link-ext' /></a>
			</div>
			<div className={styles["footer__block"]}>
				<span>
					{t('footer.rights.copyright')} &copy; 2021
				</span> {t('footer.rights.client')} 
				<div><span>{t('footer.rights.createdBy')}</span>
					<a
						href={t('footer.rights.pcss.url')}
						rel='noreferrer'
						target='_blank'
					>
						{t('footer.rights.pcss.title')}
					</a>
				</div>
			</div>
		</footer>
	);
};

export default AppFooter;