import {useEffect, useState} from 'react';

export const useWindowSize = () => {
	const windowObject = window;
	const [ windowSize, setWindowSize ] = useState({ width: windowObject.innerWidth, height: windowObject.innerHeight });

	useEffect(() => {
		const handleResize = () => setWindowSize({ width: windowObject.innerWidth, height: windowObject.innerHeight });

		windowObject.addEventListener("resize", handleResize);

		return () => windowObject.removeEventListener("resize", handleResize);
	}, []);
	return windowSize;
};