import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import PropTypes from "prop-types";
import ModalBaseCancelConfirm from '@Elements/Modal/ModalBaseCancelConfirm';

import api from "@/services/api";

import FormSubmit from "@Elements/Form/FormSubmit";
import FormInputCustom from "@Elements/Form/FormInputCustom";

import { CheckViewModePermission } from "@/permissions/ViewModes";

import { alertActions } from "@/store/actions/alert.actions";

import TranscriptionPermissionsUserItem from "@Pages/Transcription/TranscriptionPermissions/parts/TranscriptionPermissionsUserItem";
import TranscriptionPermissionsUserItemHeader from "@Pages/Transcription/TranscriptionPermissions/parts/TranscriptionPermissionsUserItemHeader";

import styles from "@Pages/Transcription/TranscriptionPermissions/TranscriptionPermissions.module.sass";

const TranscriptionPermissionsUsers = ({ users, getTranscriptionPermissionsData, summarizeData, viewMode }) => {
	const dispatch = useDispatch();
	const isLoggedIn = useSelector(state => state.auth.credentials.isLoggedIn);
	const { t } = useTranslation();

	const { transcriptionId } = useParams();
	const [isSendingEmail, setIsSendingEmail] = useState(false);
	const [deleteUserId, setDeleteUserId] = useState(null);
	const [availableRoles, setAvailableRoles] = useState([]);

	const defaultValues = {
		email: '',
	};

	const { register, handleSubmit, formState: { errors, isDirty, isValid }, setError, reset } = useForm({
		defaultValues: defaultValues,
		mode: "onBlur",
	});

	const onDeleteUser = (userId) => {
		setDeleteUserId(userId);
	};

	useEffect(() => {
		if (!isLoggedIn) return;
		getAvailableRoles();
	}, [isLoggedIn]);

	const onDeleteUserConfirmed = () => {
		api.delete(`/transcriptions/${transcriptionId}/allowed-users/user/${deleteUserId}`)
			.then(() => {
				getTranscriptionPermissionsData();
				setDeleteUserId(null);
			});
	};

	const onAddUserSubmit = (data) => {
		setIsSendingEmail(true);
		api.post(`/transcriptions/${transcriptionId}/allowed-users`, { email: data.email })
			.then(() => {
				reset();
				setIsSendingEmail(false);
				getTranscriptionPermissionsData();
				dispatch(
					alertActions.setAlert({
						type: "info",
						icon: "icon-info",
						text: "transcription.permissions.userAddedComment",
						close: true,
					}),
				);
			}).catch((error) => {
				const errorCode = error.data.code.split('.')[2];
				setError('email', { type: errorCode, message: t('form.messages.' + errorCode) });
				setIsSendingEmail(false);
			});
	};

	const getAvailableRoles = () => {
		api.get("/user-roles")
			.then(res => {
				setAvailableRoles(res.data);
			});
	};

	return (
		<>
			{/* DELETE USER MODAL */}
			<ModalBaseCancelConfirm
				modalVisible={!!deleteUserId}
				setModalVisible={() => setDeleteUserId(null)}
				title={t('modals.permissionsDeleteUser.title')}
				bodyHTML={t('modals.permissionsDeleteUser.bodyHTML')}
				cancelButtonName={t('form.cancel')}
				confirmButtonName={t('modals.permissionsDeleteUser.button')}
				onConfirm={onDeleteUserConfirmed}
			/>
			<div className={styles["permissions__userlist-header"]}>{t('transcription.permissions.users') + ` (${users.length})`}</div>
			<div className={styles["permissions__userlist"]}>
				<TranscriptionPermissionsUserItemHeader />
				{users && (
					users.map((user, index) => (
						<TranscriptionPermissionsUserItem
							orderNum={parseInt(index)}
							key={user.user.email}
							email={user.user.email}
							userId={parseInt(user.user.id)}
							role={user.role.code}
							getTranscriptionPermissionsData={getTranscriptionPermissionsData}
							onDeleteUserProps={onDeleteUser}
							summarizeData={summarizeData.filter((element) => element.userId === parseInt(user.user.id))[0]}
							viewMode={viewMode}
							availableRoles={availableRoles}
						/>
					))
				)}

			</div>
			{CheckViewModePermission("canAddUser", viewMode) &&
				<>
					<h3 className={styles["permissions__additional-text"]}>{t('transcription.permissions.addUserTitle')}</h3>
					<p>{t('transcription.permissions.addUserEmail')} <span style={{ color: "#005ca9" }}>*</span></p>
					<div className={styles["permissions__add--container"]}>
						<form
							className={styles["permissions__add--form"]}
							onSubmit={handleSubmit(onAddUserSubmit)}
						>
							<FormInputCustom
								register={register}
								name='email'
								placeholder={t('transcription.permissions.addUserPlaceholder')}
								inputContainerClassName='transcription-new__form--inline-medium'
								infoMessage={t('transcription.permissions.addUserInfo')}
								validateData={
									{
										pattern: {
											value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
											message: t('form.messages.invalidEmail'),
										},
									}
								}
								errorMessage={errors && errors.email && errors.email.message}
							/>
							<div className={styles["permissions__submit--wrapper"]}>
								<FormSubmit
									variant='submit'
									className={styles["permissions__submit--bt"]}
									label={t('transcription.permissions.addUserButton')}
									disabled={isSendingEmail || !isDirty || !isValid}
								/>
							</div>
						</form>
					</div>
				</>}
		</>
	);
};

TranscriptionPermissionsUsers.propTypes = {
	users: PropTypes.array,
	viewMode: PropTypes.string,
	summarizeData: PropTypes.array,
	getTranscriptionPermissionsData: PropTypes.func,
};

export default TranscriptionPermissionsUsers;
