import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import api from "@/services/api";
import { Container } from "react-bootstrap";

import Moment from 'react-moment';

import { reverse } from 'named-urls';
import { Link } from "react-router-dom";

import url from "@/router/urls";
import TranscriptionsItemPage from '@Elements/Transcriptions/TranscriptionsItemPage';
import TranscriptionsProgress from '@Elements/Transcriptions/TranscriptionsProgress';
import CheckMark from '@Assets/icons/Check_circle.svg';

import LinkButton from "@Elements/Button/LinkButton";

import styles from '@Elements/Transcriptions/TranscriptionsItem.module.sass';

const TranscriptionsItem = ({
	transcription,
	matchesPerTranscription,
	isSearchView,
	isEventView = false,
	isEventTranscriptionView = false,
	isUserTranscriptions,
	useMetadata,
	pagesToUse,
	searchString,
	index,
	onChangeCheckbox,
	isAddedToEvent,
}) => {
	const {
		pages,
		percentOfVerified,
		viewMode,
		creationMode,
		analyticInfo,
		transcriptionInfo,
		authorsLabel,
		monographicInfo,
		id: transcriptionId,
	} = transcription;
	const { t } = useTranslation();

	const linkToTranscriptionDetails = reverse(url.transcription.details, { transcriptionId: parseInt(transcriptionId) });
	const layers = transcription.layers.sort((a, b) => Math.sign(parseInt(a.id) - parseInt(b.id)));
	const layersNum = layers.length || 0;
	const [showAll, setShowAll] = useState(false);

	const [processingStatus, setProcessingStatus] = useState('NOT_EXISTS');

	useEffect(() => {
		getTranscriptionProcessingStatus();
	}, []);

	const getTranscriptionProcessingStatus = async () => {
		try {
			const { data } = await api.get(`transcriptions/${transcriptionId}/processing-status`);
			if (data && data.status) {
				setProcessingStatus(data.status || 'NOT_EXISTS');
			}
		} catch (err) {
			// eslint-disable-next-line
			console.log(err);
		}
	};

	const statusAcknowledge = async () => {
		try {
			await api.put(`transcriptions/${transcriptionId}/processing-status-acknowledge`);
		} catch (err) {
			// eslint-disable-next-line
			console.log(err);
		} finally {
			setProcessingStatus('NOT_EXISTS');
		}
	};

	const getLanguage = (lang) => {
		switch (lang) {
			case "polski":
				return t('transcriptions.description.language.pl');
			case "angielski":
				return t('transcriptions.description.language.en');
			case "niemiecki":
				return t('transcriptions.description.language.de');
			case "francuski":
				return t('transcriptions.description.language.fr');
			case "rosyjski":
				return t('transcriptions.description.language.ru');
			case "łacina":
				return t('transcriptions.description.language.la');
			default:
				break;
		}
	};

	const getType = (lang) => {
		switch (lang) {
			case "manuscript":
				return t('transcriptions.description.type.manuscript');
			case "print":
				return t('transcriptions.description.type.print');
		}
	};

	return (
		<div className={styles["transcr-item"]}>
			<div className={styles["transcr-item__description--left-wrapper"]}>
				<Container className={styles["transcr-item__description--container"]}>
					<div className={styles["transcr-item__description--left-left"]}>
						{isEventTranscriptionView &&
							<div className={styles["transcr-item__description--checkbox-wraper"]}>
								<input className={styles["transcr-item__description--checkbox"]} type='checkbox' checked={isAddedToEvent} value
									onChange={(e) => onChangeCheckbox(transcriptionId, e)}
								/>
							</div>}
						<Link
							to={linkToTranscriptionDetails}
							className={styles["transcr-item__description--title"]}
						>
							{index ? `${index}.` : ``} {analyticInfo?.title}
						</Link>
					</div>
					{(transcription.datePublished) &&
						<div>
							{t('transcriptions.description.sourcePublishDate')}
							<span className={styles["transcr-item__description--date"]}>
								<Moment
									format='L'
								>
									{transcription.datePublished}
								</Moment>
							</span>
						</div>}
				</Container>
			</div>

			{!isAddedToEvent &&
				<>
					<div className={styles["transcr-item__description"]}>
						<Container>
							{transcriptionInfo &&
								<div className={styles["transcr-item__description--metadata"]}>
									<div className={styles["transcr-item__description--metadata-left"]}>
										{authorsLabel &&
											<div>
												{t('transcriptions.description.auhtorLabel')} {' '} <span className='text__blue'>{authorsLabel}</span>
											</div>}
										{monographicInfo?.biblType?.code &&
											<div>
												{t('transcriptions.description.typeLabel')}{' '}<span className='text__blue'>{getType(monographicInfo.biblType.code)}</span>
											</div>}
										{analyticInfo?.language?.pl &&
											<div>
												{t('transcriptions.description.languageLabel')} <span className='text__blue'>{getLanguage(analyticInfo.language.pl)}</span>
											</div>}
									</div>
									{pages &&
										<div className={styles["transcr-item__description--metadata-right"]}>
											{t('transcriptions.description.files')}<strong>{pages.hits}</strong>
										</div>}
								</div>}
						</Container>
					</div>
					<Container>
						<div className={styles["transcr-item__description--addInfo"]}>
							<div className={styles["transcr-item__description--addInfo-inner"]}>
								<div className={styles["transcr-item__description--addInfo-layers"]}>
									{t('transcription.details.transcriptionLayersCount')}{layersNum}
								</div>
								<div className={styles["transcr-item__description--addInfo-left"]}>
									{processingStatus !== 'NOT_EXISTS' && processingStatus !== 'READY' && (
										<div className={styles[`transcr-item__description--addInfo-status${processingStatus === 'FAILED' ? '--failed' : ''}`]}>
											{t(`transcription.details.status.${processingStatus}`)}
											<span
												data-tooltip-id='custom-tooltip' data-tooltip-content={t(`transcription.details.status.${processingStatus}_comment`)}
												data-place='bottom'
											>
												<i className='icon-question' />
											</span>
											<i style={{ cursor: 'pointer' }} onClick={statusAcknowledge} className='icon-close' />
										</div>
									)}
									{isSearchView ?
										<TranscriptionsProgress
											isSearchView={isSearchView}
											transcriptionPages={pagesToUse ? pagesToUse.hitCount : 0}
											matchesPerTranscription={matchesPerTranscription}
											percentOfVerified={transcription.percentOfVerified}
											percentOfTranscribed={transcription.percentOfTranscribed}
											isUserTranscriptions={isUserTranscriptions}
											useMetadata={useMetadata}
										/> :
										<TranscriptionsProgress
											isSearchView={isSearchView}
											matchesPerTranscription={matchesPerTranscription}
											percentOfVerified={transcription.percentOfVerified}
											percentOfTranscribed={transcription.percentOfTranscribed}
											isUserTranscriptions={isUserTranscriptions}
											useMetadata={useMetadata}
										/>}
								</div>
							</div>
							{(isEventView && pages.hits > 0) &&
								<div className={styles["transcr-item__footer__show-all"]}>
									<span onClick={() => { setShowAll(!showAll); }}>
										{!showAll ? t('transcriptions.item.expandMatches') : t('transcriptions.item.foldMatches')}
										{showAll ? <i className='icon-arrow-up-full' /> : <i className='icon-arrow-down-full' />}
									</span>
								</div>}
							<div />
						</div>

						<div className={styles[`transcr-item__pages${processingStatus === 'PROCESSING' ? '--disabled' : ''}`]}>
							{(searchString && searchString !== null) ?
								(pagesToUse &&
									(pagesToUse.length > 0) ?
									pagesToUse.slice(0, showAll ? pagesToUse.length : 6).map((page) => (
										<TranscriptionsItemPage
											key={page.page.id}
											page={page.page}
											transcriptionId={parseInt(transcriptionId)}
											percentOfVerified={percentOfVerified}
											viewMode={viewMode}
											creationMode={creationMode}
											matchesPerTranscription={page}
											layers={layers}
										/>
									)) :
									(pages &&
										(pages.list.length > 0) && pages.list.map((page) =>
										(
											<TranscriptionsItemPage
												key={page.id}
												page={page}
												transcriptionId={parseInt(transcriptionId)}
												percentOfVerified={percentOfVerified}
												viewMode={viewMode}
												creationMode={creationMode}
												layers={layers}
											/>
										))
									)
								) :
								((isEventView && showAll || !isEventView) && pages &&
									(pages.list.length > 0) && pages.list.map((page) =>
									(
										<TranscriptionsItemPage
											key={page.id}
											page={page}
											transcriptionId={parseInt(transcriptionId)}
											percentOfVerified={percentOfVerified}
											viewMode={viewMode}
											creationMode={creationMode}
											layers={layers}
										/>
									),
								))}
						</div>
						<div className={styles["transcr-item__footer"]}>
							{(!isEventView && searchString && searchString !== null && pagesToUse && pagesToUse.length > 6) &&
								<div className={styles["transcr-item__footer__first"]} />}
							<LinkButton
								variant='secondary'
								className={styles["transcr-item__button"]}
								to={linkToTranscriptionDetails}
								disabled={processingStatus === 'PROCESSING'}
							>
								{t('transcriptions.description.showMore')}
							</LinkButton>
							{(!isEventView && searchString && searchString !== null && pagesToUse && pagesToUse.length > 6) &&
								<div className={styles["transcr-item__footer__show-all"]}>
									<span onClick={() => { setShowAll(!showAll); }}>
										{!showAll ? t('transcriptions.item.expandMatches') : t('transcriptions.item.foldMatches')}
										{showAll ? <i className='icon-arrow-up-full' /> : <i className='icon-arrow-down-full' />}
									</span>
								</div>}
						</div>
					</Container>
				</>}

			{
				isAddedToEvent &&
				<div className={styles["transcr-item__added-to-event"]}>
					<div>
						<img src={CheckMark} alt='check mark' />
					</div>
					<p className={styles["transcr-item__added-to-event__text"]}>{t('transcriptions.item.toTranscription')}</p>
				</div>
			}
		</div >
	);
};

TranscriptionsItem.propTypes = {
	transcription: PropTypes.object,
	matchesPerTranscription: PropTypes.number,
	isSearchView: PropTypes.bool,
	isUserTranscriptions: PropTypes.bool,
	useMetadata: PropTypes.string,
	pagesToUse: PropTypes.array,
	searchString: PropTypes.string,
	index: PropTypes.number,
	isEventView: PropTypes.bool,
	isEventTranscriptionView: PropTypes.bool,
	onChangeCheckbox: PropTypes.func,
	isAddedToEvent: PropTypes.bool,

};

export default TranscriptionsItem;